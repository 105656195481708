/* eslint-disable @typescript-eslint/no-explicit-any */
export function sortChildrenByTitleAsc(objects: any[]): any[] {
  objects.forEach((obj) => {
    if (obj.children) {
      obj.children.sort((a: any, b: any) => {
        const titleA = (a.data.title as string) ?? '';
        const titleB = (b.data.title as string) ?? '';
        return titleA.localeCompare(titleB);
      });
      // Recursively sort children of children
      sortChildrenByTitleAsc(obj.children);
    }
  });
  return objects;
}
