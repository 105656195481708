import { AppDispatch, RootState } from '@/store';
import { changeExpanded } from '@/store/storeSlice';
import {
  ResourceClassTree,
  ResourceSubClassTree,
  ResourceTree,
  ResourceTypeTree,
  SiteTree,
  TSimulationMenuOptions,
  TreeItem,
  WipItemParams,
  WipTree,
  WipTreeElement,
} from '@/store/types';
import { Input, Table } from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { TAnchorEl } from '@data-products-and-ai/react-components/lib/components/Layout/Menu/Menu';
import { ReactNode, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextMenu } from '../ContextMenu';
import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';

interface ProductTreeRender extends WipItemParams {
  RESOURCE_NAME: TreeItem;
  SITE_ID: TreeItem;
  SITE: TreeItem;
  RESOURCE_ID: TreeItem;
  BUILDING_ID: TreeItem;
  BUILDING: TreeItem;
  RESOURCE_AVAILABILITY_ILLNESS: TreeItem;
  RESOURCE_AVAILABILITY_TECH: TreeItem;
  RESOURCE_AVAILABILITY_VACATION: TreeItem;
  RESOURCE_GRADE_ID: TreeItem;
  RESOURCE_GRADE: TreeItem;
  RESOURCE_MAX_CAPACITY: TreeItem;
  RESOURCE_MTTR_ILLNESS: TreeItem;
  RESOURCE_MTTR_TECH: TreeItem;
  RESOURCE_MTTR_VACATION: TreeItem;
  RESOURCE_SHIFT_CALENDAR_ID: TreeItem;
  RESOURCE_SHIFT_CALENDAR: TreeItem;
}

type ProductTreeRenderGeneric = {
  [K in keyof ProductTreeRender]: string | number | ReactNode;
};

type TProductStructure = {
  id: string;
  data: ProductTreeRenderGeneric;
  children: TProductStructure[];
  isClickable?: boolean;
  isExpanded?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  isSelectable?: boolean;
  area: keyof WipTree;
};

function findAndPushItem(
  obj: TProductStructure[],
  idToFind: string,
  newItem: TProductStructure,
): boolean {
  for (const item of obj) {
    if (item.id === idToFind) {
      if (!item.children) {
        item.children = []; // Ensure children array exists before pushing
      }
      item.children.push(newItem);

      item.children.sort((a, b) => {
        const stepA = (a.data.RESOURCE_NAME as string) || ''; // Use 0 as a default value if STEP.value is null or undefined
        const stepB = (b.data.RESOURCE_NAME as string) || ''; // Use 0 as a default value if STEP.value is null or undefined

        return stepA.localeCompare(stepB);
      });
      return true; // Return true to stop further recursion once the item is found
    } else if (item.children) {
      if (findAndPushItem(item.children, idToFind, newItem)) {
        return true; // If the item is found in the child, stop further recursion
      }
    }
  }
  return false; // Return false if the item is not found in the current objects or its children
}
const Resources = () => {
  /*  const dispatch: AppDispatch = useDispatch(); */
  const { setSimulationParams } = useContext(SimulationCtx);
  const [anchorEl, setAnchorEl] = useState<TAnchorEl>(null);
  const [selectedRow, setSelectedRow] = useState<TableRowData | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected === true),
  );

  const [filterQuery, setFilterQuery] = useState<string>('');

  const generateObjectWithChildren = (
    sitesObject: WipTreeElement<SiteTree>,
    resourcesTypesObject: WipTreeElement<ResourceTypeTree>,
    resourcesClassesObject: WipTreeElement<ResourceClassTree>,
    resourcesSubClassesObject: WipTreeElement<ResourceSubClassTree>,
    resourcesObject: WipTreeElement<ResourceTree>,
  ): TProductStructure[] => {
    const objectWithChildren: TProductStructure[] = [];
    const itemMap: { [itemId: string]: TProductStructure } = {};

    sitesObject.allIds.forEach((itemId: string) => {
      const item = sitesObject.byId[itemId];

      const newItem: TProductStructure = {
        id: item.SITE_ID.originalValue,
        area: 'sites',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.SITE_ID.originalValue,
          title: item.SITE.value,
          is_deleted: item.is_deleted,
          is_wip: item.is_wip,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          RESOURCE_NAME: item.SITE.value,
          SITE_ID: '',
          SITE: '',
          RESOURCE_ID: '',
          BUILDING_ID: '',
          RESOURCE_AVAILABILITY_ILLNESS: '',
          RESOURCE_AVAILABILITY_TECH: '',
          RESOURCE_AVAILABILITY_VACATION: '',
          RESOURCE_GRADE_ID: '',
          RESOURCE_MAX_CAPACITY: '',
          RESOURCE_MTTR_ILLNESS: '',
          RESOURCE_MTTR_TECH: '',
          RESOURCE_MTTR_VACATION: '',
          RESOURCE_SHIFT_CALENDAR_ID: '',
          BUILDING: '',
          RESOURCE_GRADE: '',
          RESOURCE_SHIFT_CALENDAR: '',
          children: [],
        },
        children: [],
      };

      objectWithChildren.push(newItem);
      itemMap[itemId] = newItem;
    });

    resourcesTypesObject.allIds.forEach((itemId: string) => {
      const item = resourcesTypesObject.byId[itemId];
      const siteId = item.SITE_ID.value;

      if (!sitesObject.byId[siteId]) return;

      const newItem: TProductStructure = {
        id: item.RESOURCE_TYPE_ID.originalValue,
        area: 'resource_types',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.RESOURCE_TYPE_ID.originalValue,
          title: item.RESOURCE_TYPE.value,
          is_deleted: item.is_deleted,
          is_wip: item.is_wip,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          RESOURCE_NAME: item.RESOURCE_TYPE.value,
          SITE_ID: '',
          SITE: '',
          RESOURCE_ID: '',
          BUILDING_ID: '',
          RESOURCE_AVAILABILITY_ILLNESS: '',
          RESOURCE_AVAILABILITY_TECH: '',
          RESOURCE_AVAILABILITY_VACATION: '',
          RESOURCE_GRADE_ID: '',
          RESOURCE_MAX_CAPACITY: '',
          RESOURCE_MTTR_ILLNESS: '',
          RESOURCE_MTTR_TECH: '',
          RESOURCE_MTTR_VACATION: '',
          RESOURCE_SHIFT_CALENDAR_ID: '',
          BUILDING: '',
          RESOURCE_GRADE: '',
          RESOURCE_SHIFT_CALENDAR: '',
          children: [],
        },

        children: [],
      };

      const foundObject = objectWithChildren.find((obj) => obj.id === siteId);
      if (foundObject) {
        foundObject.children.push(newItem);
      } /* 
      objectWithChildren
        .find((obj) => obj.id === siteId)
        .children.push(newItem); */

      // itemMap[siteId].children.push(newItem);

      // Store the item in the map for later reference
      itemMap[itemId] = newItem;
    });

    resourcesClassesObject.allIds.forEach((itemId: string) => {
      const item = resourcesClassesObject.byId[itemId];
      const typeID = item.RESOURCE_TYPE_ID.value;

      const siteId = resourcesTypesObject.byId[typeID].SITE_ID.value;

      if (!sitesObject.byId[siteId]) return;

      const newItem: TProductStructure = {
        id: item.RESOURCE_CLASS_ID.originalValue,
        area: 'resource_classes',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.RESOURCE_CLASS_ID.originalValue,
          title: item.RESOURCE_CLASS.value,
          is_deleted: item.is_deleted,
          is_wip: item.is_wip,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          RESOURCE_NAME: item.RESOURCE_CLASS.value,
          SITE_ID: '',
          SITE: '',
          RESOURCE_ID: '',
          BUILDING_ID: '',
          RESOURCE_AVAILABILITY_ILLNESS: '',
          RESOURCE_AVAILABILITY_TECH: '',
          RESOURCE_AVAILABILITY_VACATION: '',
          RESOURCE_GRADE_ID: '',
          RESOURCE_MAX_CAPACITY: '',
          RESOURCE_MTTR_ILLNESS: '',
          RESOURCE_MTTR_TECH: '',
          RESOURCE_MTTR_VACATION: '',
          RESOURCE_SHIFT_CALENDAR_ID: '',
          BUILDING: '',
          RESOURCE_GRADE: '',
          RESOURCE_SHIFT_CALENDAR: '',
          children: [],
        },

        children: [],
      };

      findAndPushItem(objectWithChildren, typeID, newItem);
      /*  objectWithChildren
        .find((obj) => obj.id === typeID)
        .children.push(newItem);
 */
      itemMap[itemId] = newItem;
    });

    resourcesSubClassesObject.allIds.forEach((itemId: string) => {
      const item = resourcesSubClassesObject.byId[itemId];
      const classID = item.RESOURCE_CLASS_ID.value;
      const typeID =
        resourcesClassesObject.byId[classID].RESOURCE_TYPE_ID.value;

      const siteId = resourcesTypesObject.byId[typeID].SITE_ID.value;

      if (!sitesObject.byId[siteId]) return;

      const newItem: TProductStructure = {
        id: item.RESOURCE_SUB_CLASS_ID.originalValue,
        area: 'resource_sub_classes',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.RESOURCE_SUB_CLASS_ID.originalValue,
          title: item.RESOURCE_SUB_CLASS.value,
          is_deleted: item.is_deleted,
          is_wip: item.is_wip,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          RESOURCE_NAME: item.RESOURCE_SUB_CLASS.value,
          SITE_ID: '',
          SITE: '',
          RESOURCE_ID: '',
          BUILDING_ID: '',
          RESOURCE_AVAILABILITY_ILLNESS: '',
          RESOURCE_AVAILABILITY_TECH: '',
          RESOURCE_AVAILABILITY_VACATION: '',
          RESOURCE_GRADE_ID: '',
          RESOURCE_MAX_CAPACITY: '',
          RESOURCE_MTTR_ILLNESS: '',
          RESOURCE_MTTR_TECH: '',
          RESOURCE_MTTR_VACATION: '',
          RESOURCE_SHIFT_CALENDAR_ID: '',
          BUILDING: '',
          RESOURCE_GRADE: '',
          RESOURCE_SHIFT_CALENDAR: '',
          children: [],
        },

        children: [],
      };

      findAndPushItem(objectWithChildren, classID, newItem);
      /*  objectWithChildren
        .find((obj) => obj.id === typeID)
        .children.push(newItem);
 */
      itemMap[itemId] = newItem;
    });

    resourcesObject.allIds.forEach((itemId: string) => {
      const item = resourcesObject.byId[itemId];
      const subclassID = item.RESOURCE_SUB_CLASS_ID.value;

      const siteId = item.SITE_ID.value;

      if (!sitesObject.byId[siteId]) return;

      const newItem: TProductStructure = {
        id: item.RESOURCE_ID.originalValue,
        area: 'resources',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.RESOURCE_ID.originalValue,
          title: item.RESOURCE_NAME.value,
          is_deleted: item.is_deleted,
          is_wip: item.is_wip,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          RESOURCE_NAME: item.RESOURCE_NAME.value,
          SITE_ID: item.SITE_ID.value,
          SITE:
            selectedScenario?.data.sites.byId[item.SITE_ID.value]
              .SITE_ABBREVIATION.value ?? 'N/A',
          RESOURCE_ID: item.RESOURCE_ID.value,
          BUILDING_ID: item.BUILDING_ID.value,
          RESOURCE_AVAILABILITY_ILLNESS:
            item.RESOURCE_AVAILABILITY_ILLNESS.value,
          RESOURCE_AVAILABILITY_TECH: item.RESOURCE_AVAILABILITY_TECH.value,
          RESOURCE_AVAILABILITY_VACATION:
            item.RESOURCE_AVAILABILITY_VACATION.value,
          RESOURCE_GRADE_ID: item.RESOURCE_GRADE_ID.value,
          RESOURCE_MAX_CAPACITY: item.RESOURCE_MAX_CAPACITY.value,
          RESOURCE_MTTR_ILLNESS: item.RESOURCE_MTTR_ILLNESS.value,
          RESOURCE_MTTR_TECH: item.RESOURCE_MTTR_TECH.value,
          RESOURCE_MTTR_VACATION: item.RESOURCE_MTTR_VACATION.value,
          RESOURCE_SHIFT_CALENDAR_ID: item.RESOURCE_SHIFT_CALENDAR_ID.value,
          BUILDING:
            selectedScenario?.data.resource_buildings.byId[
              item.BUILDING_ID.value
            ].BUILDING.value ?? 'N/A',
          RESOURCE_GRADE:
            selectedScenario?.data.resource_grades.byId[
              item.RESOURCE_GRADE_ID.value
            ].RESOURCE_GRADE.value ?? 'N/A',
          RESOURCE_SHIFT_CALENDAR:
            selectedScenario?.data.shift_calendar.byId[
              item.RESOURCE_SHIFT_CALENDAR_ID.value
            ].SHIFT_CALENDAR.value ?? 'N/A',
          children: [],
        },

        children: [],
      };

      if (!newItem.data.is_deleted) {
        findAndPushItem(objectWithChildren, subclassID, newItem);

        itemMap[itemId] = newItem;
      }
    });

    return objectWithChildren;
  };

  if (!selectedScenario) return <>No Scenario</>;

  const objectWithChildren = generateObjectWithChildren(
    selectedScenario.data.sites,
    selectedScenario.data.resource_types,
    selectedScenario.data.resource_classes,
    selectedScenario.data.resource_sub_classes,
    selectedScenario.data.resources,
  );

  const TableHeaders: TableHeaderType[] = [
    {
      title: 'Resource',
      align: 'left',
      columnKey: 'RESOURCE_NAME',
      columnWidth: '400px',
    },
    {
      title: 'Site',
      align: 'center',
      columnKey: 'SITE',
    },
    {
      title: 'Shift ',
      align: 'center',
      columnKey: 'RESOURCE_SHIFT_CALENDAR',
    },
    {
      title: 'Building',
      align: 'center',
      columnKey: 'BUILDING',
    },
    {
      title: 'Grade',
      align: 'center',
      columnKey: 'RESOURCE_GRADE',
    },
    {
      title: 'Max Capacity',
      align: 'center',
      columnKey: 'RESOURCE_MAX_CAPACITY',
    },
    {
      title: 'Availability Illness',
      align: 'center',
      columnKey: 'RESOURCE_AVAILABILITY_ILLNESS',
    },
    {
      title: 'Availability Tech',
      align: 'center',
      columnKey: 'RESOURCE_AVAILABILITY_TECH',
    },
    {
      title: 'Availability Vacation',
      align: 'center',
      columnKey: 'RESOURCE_AVAILABILITY_VACATION',
    },
  ];

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(e.target.value);
  };

  const handleClick = (value: TableRowData) => {
    if (!value.children) return;
    if (value.children.length <= 0) return;

    dispatch(
      changeExpanded({
        id: value.id,
        isExpanded: value.isExpanded ?? false,
        area: value.area as keyof WipTree,
      }),
    );
  };

  const handleDoubleClick = (value: TableRowData) => {
    const area = value.area as TSimulationMenuOptions;
    if (area !== 'resources') return;

    setSimulationParams((prevState) => ({
      ...prevState,
      drawerOpen: true,
      formItem: value.id,
      formArea: area,
    }));
  };

  const handleMenuRight = (
    value: TableRowData,
    event?: React.MouseEvent<HTMLTableRowElement>,
  ) => {
    const area = value.area as keyof WipTree;

    if (area !== 'resources') return;
    event &&
      setAnchorEl({
        left: event.clientX,
        top: event.clientY,
      });

    setSelectedRow(value);
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Input id="Filter" onChange={handleInput} placeholder="Filter" />
      </div>

      <ContextMenu
        selectedRow={selectedRow}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      />
      <Table
        headers={TableHeaders}
        width={'100%'}
        isSelectable={false}
        hasFilter={true}
        filterQuery={filterQuery}
        rows={objectWithChildren}
        pointerCursor={true}
        typographyTag="textsmall"
        onRowClick={(value) => handleClick(value)}
        onRowDoubleClick={(value) => handleDoubleClick(value)}
        onRowRightClick={(value, event) => handleMenuRight(value, event)}
        stickyHeader={true}
        headerVerticalAlign="bottom"
      />

      {/* {objectWithChildren.map((item, index) => {
        return <Line key={index} id={item.PRODUCT_ID} />;
      })} */}

      {/*    {JSON.stringify(
        simulation.scenarios.filter((item) => item.is_selected === true)
      )} */}
    </>
  );
};

export default Resources;
