import { Login } from '@/pages/Login';
import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { LanguageProvider } from '@data-products-and-ai/react-components';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { CONSTANTS } from './constants/constants';
import { Simulation } from './pages/Simulation';
import { store } from './store';

function App() {
  /*Make_reusable - page not found */
  const NotFoundPage = () => {
    return <>Not found</>;
  };

  const Main = () => (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <Simulation />
    </MsalAuthenticationTemplate>
  );

  const LoginToggle = () => {
    return (
      <>
        <LanguageProvider translations={CONSTANTS} defaultLanguage="en">
          <AuthenticatedTemplate>
            <StoreProvider store={store}>
              <Main />
            </StoreProvider>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
        </LanguageProvider>
      </>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/notfound" element={<NotFoundPage />} />
        <Route path="*" element={<LoginToggle />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
