const LoginTitle = () => {
  return (
    <div
      style={{
        marginTop: 10,
        position: 'relative',
        height: 170,
      }}
    >
      <div
        style={{
          position: 'absolute',
          fontSize: 20,
          top: 16,
          left: 102,
          letterSpacing: 0.5,
        }}
      >
        Factory
      </div>
      <div
        style={{
          position: 'absolute',
          fontSize: 40,
          top: 30,
          left: 81,
          fontWeight: 'bold',
        }}
      >
        Digital
      </div>
      <div
        style={{
          position: 'absolute',
          fontSize: 40,
          top: 64,
          left: 142,
          fontWeight: 'bold',
        }}
      >
        Twin
      </div>
      <div
        style={{
          position: 'absolute',
          fontSize: 18,
          top: 110,
          left: 150,
          letterSpacing: 0.5,
        }}
      >
        Simulator
      </div>
    </div>
  );
};

export default LoginTitle;
