import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { useAccessToken, useGroups } from '@/hooks';
import { LoadWip } from '@/pages/LoadWip';
import { ApiConnector } from '@/services/ApiConnector';
import { AppDispatch, RootState } from '@/store';
import { resetWIP, startSimulation } from '@/store/storeSlice';
import { Simulation, Site } from '@/store/types';
import {
  Button,
  Checkbox,
  DatePicker,
  HandleError,
  Input,
  Label,
  Stack,
  Textarea,
  Typography,
} from '@data-products-and-ai/react-components';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';

type FormType = {
  is_started: boolean;
  title: string;
  sites: string[];
  description: string;
  date_start: number;
  date_end: number;
};

const initialState: FormType = {
  is_started: false,
  title: '',
  sites: [],
  date_start: 0,
  date_end: 0,
  description: '',
};

type SelectedSite = {
  id: string;
  name: string;
};
type TLoadingWipStatus = 'loading' | 'success' | 'error' | 'idle';

const CreateSimulationForm = () => {
  const dispatch: AppDispatch = useDispatch();
  const { getSilentToken } = useAccessToken();

  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  const [selectedSites, setSelectedSites] = useState<SelectedSite[]>([]);
  const [loadingWipStatus, setloadingWipStatus] =
    useState<TLoadingWipStatus>('idle');
  const [currentPage, setCurrentPage] = useState(1);

  const [form, setForm] = useState(initialState);
  const productionOrders = useSelector(
    (state: RootState) => state.store.WIP.production_orders,
  );

  const GetSites = async () => {
    function isKeyExists(
      obj: {
        [key: string]: number;
      },
      value: number,
    ) {
      for (const key in obj) {
        if (obj[key] === value && groups?.includes(key)) {
          return true;
        }
      }
      return false;
    }

    const groups = useGroups();
    const url = import.meta.env.VITE_API_URL;

    const authGroups: {
      [key: string]: number;
    } = JSON.parse(import.meta.env.VITE_AUTH);

    try {
      const token = await getSilentToken();

      const response = await ApiConnector(
        'GET',
        'tables/V_WIP_SITE' + import.meta.env.VITE_VIEWS_SUFFIX,
        { baseUrl: url, accessToken: token },
        { 'Content-Type': 'application/json', Accept: 'application/json' },
      );

      const responseData = response.data.body.data;

      const responseArr: SelectedSite[] = [];

      responseData.forEach((site: string[]) => {
        if (
          (groups && isKeyExists(authGroups, parseInt(site[0]))) ||
          isKeyExists(authGroups, 0)
        )
          responseArr.push({ id: site[0], name: site[2] });
      });

      setSelectedSites(
        responseArr.sort((a: SelectedSite, b: SelectedSite) => {
          const stepA = a.name || '';
          const stepB = b.name || '';

          return stepA.localeCompare(stepB);
        }),
      );

      // You can now use responseObj.data as needed
    } catch (error) {
      // Handle any errors here
      console.log(error);
    }
  };

  useEffect(() => {
    GetSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredData = productionOrders.filter(
      (item) => item.PARENT_PRODUCTION_ORDER_ID.value === null,
    );

    const startDates = filteredData
      .filter((item) => item.PO_START_DATE_TIMESTAMP.value !== null)
      .map((item) =>
        typeof item.PO_START_DATE_TIMESTAMP.value === 'number'
          ? item.PO_START_DATE_TIMESTAMP.value
          : parseInt(item.PO_START_DATE_TIMESTAMP?.value || ''),
      );
    const endDates = filteredData
      .filter((item) => item.PO_END_DATE_TIMESTAMP.value !== null)
      .map((item) =>
        typeof item.PO_END_DATE_TIMESTAMP.value === 'number'
          ? item.PO_END_DATE_TIMESTAMP.value
          : parseInt(item.PO_END_DATE_TIMESTAMP?.value || ''),
      );

    const minStartDate = Math.min(...startDates);
    const maxEndDate = Math.max(...endDates);

    setForm((prevState) => ({
      ...prevState,
      date_start: minStartDate,
      date_end: maxEndDate,
    }));
  }, [productionOrders]);

  const handleCheckboxChange = (value: string) => {
    if (form.sites.includes(value)) {
      setForm((prevState) => ({
        ...prevState,
        sites: prevState.sites.filter((item) => item !== value),
      }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        sites: [...prevState.sites, value],
      }));
    }
  };

  const handleGetData = () => {
    setSimulationParams((prevState) => ({
      ...prevState,
      selectedSites: form.sites,
      factoryDataLoaded: null,
    }));
    setloadingWipStatus('loading');
  };

  const handleCancel = () => {
    dispatch(resetWIP());
    setSimulationParams((prevState) => ({
      ...prevState,
      simulationForm: false,
      factoryDataLoaded: null,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const obj: Omit<Simulation, 'scenarios'> = {
      is_started: true,
      title: form.title,
      description: form.description,
      date_start: form.date_start,
      date_end: form.date_end,
      sites: form.sites,
    };

    dispatch(
      startSimulation({
        simulation: obj,
      }),
    );

    setSimulationParams((prevState) => ({
      ...prevState,
      simulationForm: false,
      simulationStarted: true,
    }));

    console.log(obj);
    return;

    /*  setLoaderMessage("Creating simulation");
    setTimeout(() => {
      dispatch({
        type: "CREATE_SIMULATION",
        payload: obj,
      });
      setCreateSimulationIsClicked(false);
      setLoaderMessage(null);
    }, 1000); */
  };

  return (
    <>
      {/*  <pre>{JSON.stringify(simulation, null, 2)}</pre> */}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 60,
        }}
      >
        <div
          style={{
            width: '70%',
            padding: 40,
            border: 'solid 1px #ededed',
            borderRadius: 8,
            backgroundColor: '#FFFFFF',
          }}
        >
          {/*
           */}
          {/*  {simulationParams.factoryDataLoaded} */}

          <form onSubmit={handleSubmit}>
            {currentPage === 1 && (
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                  <Label tag="textsmall_strong">
                    Please select the sites to include in the simulation
                  </Label>

                  <Stack direction="row" gap={20}>
                    {selectedSites.length === 0 ? (
                      <Typography tag="textsmall">Loading sites...</Typography>
                    ) : (
                      selectedSites.map((item: SelectedSite, index: number) => (
                        <Typography key={index} tag="textsmall">
                          <Checkbox
                            label={item.name}
                            value={item.id}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                        </Typography>
                      ))
                    )}
                  </Stack>

                  {loadingWipStatus === 'loading' && <LoadWip />}

                  {form.sites.length >= 1 && productionOrders.length === 0 && (
                    <div style={{ textAlign: 'center', marginTop: 30 }}>
                      <Button
                        buttonType="button"
                        onClick={() => handleGetData()}
                      >
                        Get Data
                      </Button>
                    </div>
                  )}

                  {form.sites.length >= 1 && productionOrders.length > 0 && (
                    <div style={{ textAlign: 'center', marginTop: 30 }}>
                      <Button
                        buttonType="button"
                        onClick={() => setCurrentPage(2)}
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}

            {currentPage === 2 && (
              <>
                <Label tag="textsmall_strong">
                  Sites to include in the simulation
                </Label>
                {selectedSites.map((item: SelectedSite, index: number) => {
                  if (form.sites.includes(item.id)) {
                    return (
                      <Typography key={index} tag="textsmall">
                        {item.name}
                      </Typography>
                    );
                  }
                  return null;
                })}

                <Label tag="textsmall_strong" marginTop={20}>
                  Title
                </Label>
                <HandleError mandatory mandatoryError="Title is mandatory">
                  <Input
                    maxLength={60}
                    autofocus={true}
                    placeholder="Write a title for your simulation"
                    id="Simulation__FC__title"
                    onChange={(e) => {
                      setForm((prevState) => ({
                        ...prevState,
                        title: e.target.value,
                      }));
                    }}
                  ></Input>
                </HandleError>

                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 20px 1fr',
                  }}
                >
                  <div>
                    <Label tag="textsmall_strong" marginTop={20}>
                      Start Date
                    </Label>

                    <DatePicker
                      id="Simulation__FC__startDate"
                      defaultValue={form.date_start}
                      error={
                        form.date_start > form.date_end ||
                        form.date_start === 0 ||
                        form.date_end === 0
                      }
                      onChange={(value) => {
                        setForm((prevState) => ({
                          ...prevState,
                          date_start: value ? value : 0,
                        }));
                      }}
                    />
                  </div>
                  <div></div>
                  <div>
                    <Label tag="textsmall_strong" marginTop={20}>
                      End Date
                    </Label>
                    <DatePicker
                      id="Simulation__FC__endDate"
                      defaultValue={form.date_end}
                      error={
                        form.date_start > form.date_end ||
                        form.date_start === 0 ||
                        form.date_end === 0
                      }
                      onChange={(value) => {
                        setForm((prevState) => ({
                          ...prevState,
                          date_end: value ? value : 0,
                        }));
                      }}
                    />
                  </div>

                  {form.date_start > form.date_end ||
                  form.date_start === 0 ||
                  form.date_end === 0 ? (
                    <div className="form-error-message">
                      <Typography tag="textsmall">
                        Invalid date interval
                      </Typography>
                    </div>
                  ) : null}
                </div>

                <Label tag="textsmall_strong" marginTop={20}>
                  Description
                </Label>
                <Textarea
                  id="Simulation__FC__description"
                  onChange={(e) => {
                    setForm((prevState) => ({
                      ...prevState,
                      description: e.target.value,
                    }));
                  }}
                ></Textarea>
                {form.title.length <= 0 ||
                form.date_start > form.date_end ||
                form.date_start === 0 ||
                form.date_end === 0 ||
                form.sites.length < 1 ? null : (
                  <div style={{ textAlign: 'center', marginTop: 30 }}>
                    <Button
                      buttonType="submit"
                      disabled={
                        form.title.length <= 0 ||
                        form.date_start > form.date_end ||
                        form.date_start === 0 ||
                        form.date_end === 0
                      }
                    >
                      Create Simulation
                    </Button>
                  </div>
                )}
              </>
            )}
          </form>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: 20 }}>
        <Button type="link" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};
export default CreateSimulationForm;
