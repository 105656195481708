import {
  Button,
  Logo,
  Stack,
  useTranslation,
} from '@data-products-and-ai/react-components';
import styles from './Login.module.scss';
import Background from './assets/background.jpg';
import { AzureLoginButton } from './components/AzureLoginButton';
import { LoginTitle } from './components/LoginTitle';

const Login = () => {
  const translate = useTranslation();

  const handleEmail = () => {
    const mail = document.createElement('a');
    mail.href = `mailto:pavel.gocev@siemens-energy.com?subject=${translate(
      'LOGIN.REQUEST_ACCESS_EMAIL_SUBJECT',
    )}`; /* ${LOGIN.REQUEST_ACCESS_EMAIL_SUBJECT} */
    mail.click();
    mail.remove();
  };

  const handleTicket = () => {
    window.open(
      'https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/89a0f1ea-22a2-4ace-a2d7-9725774347af?hidenavbar=true&Lookup=13',
    );
  };

  return (
    <div
      className={styles.loginContainer}
      style={
        {
          '--bg-image': `url(${Background})`,
        } as React.CSSProperties
      }
    >
      {/* Make_reusable box - extend div  
      backgroundImage:
      backgroundColor: You can use this prop to allow users to specify the background color of the component. (only specific colors)
borderRadius: This prop could be used to adjust the border-radius of the component.
padding: This prop could be used to adjust the padding of the component.
margin: This prop could be used to adjust the margin of the component.
width and height: These props could be used to adjust the dimensions of the component.
fontSize: This prop could be used to adjust the font size of the text within the component.
fontWeight: This prop could be used to adjust the font weight of the text within the component.
color: This prop could be used to adjust the color of the text within the component.
hoverEffect: This prop could be used to apply a specific hover effect when the mouse is over the component.
animation: This prop could be used to apply a specific CSS animation to the component.
      isVisible: A boolean prop to control the visibility of the component.
isLoading: A boolean prop that could be used to show a loading spinner inside the component.
isDisabled: A boolean prop to disable any interactive elements within the component.
onHover, onFocus, onBlur: Function props to handle certain events.
tooltipText: A string prop to display a tooltip on hover.
data: An object or array prop to pass data into the component.
validation: A prop to handle validation rules for a form input.
format: A prop to specify the format of the content (e.g., for date or number formatting).*/}

      <div className={styles.loginFormContainer}>
        <Logo background="light" size="medium" />
        <LoginTitle />

        <div style={{ marginTop: 15 }}>
          <AzureLoginButton />
        </div>

        <div className={styles.loginFormAdditional}>
          {/* Make_reusable divider  */}
          <hr />

          <Stack direction="row" horizontalAlign="center">
            <Button type="link" onClick={handleEmail} allCaps={false}>
              {translate('LOGIN.REQUEST_ACCESS')}
            </Button>

            {/* Make_reusable divider  */}
            <span style={{ marginLeft: 10, marginRight: 10 }}>|</span>

            <Button type="link" onClick={handleTicket} allCaps={false}>
              {translate('LOGIN.SUPPORT')}
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Login;
