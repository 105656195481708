import { useContext, useState } from 'react';

import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { AppDispatch, RootState } from '@/store';
import {
  deleteScenario,
  duplicateScenario,
  selectScenario,
} from '@/store/storeSlice';
import { Scenario as TScenario } from '@/store/types';
import {
  ButtonIcon,
  Stack,
  TextTruncate,
  Typography,
} from '@data-products-and-ai/react-components';
import { produce } from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import { Scenario } from './ScenarioListItem.styled';
type ScenarioProps = {
  item: TScenario;
};

const ScenarioListItem = ({ item }: ScenarioProps) => {
  const { setSimulationParams } = useContext(SimulationCtx);
  const dispatch: AppDispatch = useDispatch();
  const simulation = useSelector((state: RootState) => state.store.Simulation);
  const [showAdditionalDiv, setShowAdditionalDiv] = useState(false);

  const handleMouseOver = () => {
    setShowAdditionalDiv(true);
  };

  const handleMouseOut = () => {
    setShowAdditionalDiv(false);
    // setShowDelete(false);
  };
  const handleClick = (item: TScenario) => {
    dispatch(
      selectScenario({
        scenario: item,
      }),
    );

    setSimulationParams(
      produce((draft) => {
        draft.simulationOptionSelected = 'scenario_settings';
      }),
    );
  };
  const handleDuplicate = (item: TScenario, event: React.MouseEvent) => {
    dispatch(
      duplicateScenario({
        scenario: item,
      }),
    );
    event.stopPropagation();
  };

  const handleDelete = (item: TScenario, event: React.MouseEvent) => {
    dispatch(
      deleteScenario({
        scenario: item,
      }),
    );

    event.stopPropagation();
  };

  return (
    <Scenario
      $is_selected={item.is_selected}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={() => handleClick(item)}
      className="noselect"
    >
      <div id="scenario-title" style={{ overflowWrap: 'break-word' }}>
        <Typography tag="textsmall">
          <TextTruncate width={showAdditionalDiv ? '80%' : '95%'}>
            {item.settings.title}
          </TextTruncate>
        </Typography>
      </div>
      {showAdditionalDiv && (
        <div style={{ position: 'absolute', top: 4, right: 5, opacity: 0.7 }}>
          <Stack direction="row" gap={5}>
            <ButtonIcon
              icon="IconCopy"
              size="extra-small"
              tooltipMessage="Duplicate"
              onClick={(event) => handleDuplicate(item, event)}
            />
            <ButtonIcon
              icon="IconClose"
              size="extra-small"
              tooltipMessage={
                simulation.scenarios.length > 1 ? 'Remove' : undefined
              }
              onClick={(event) => handleDelete(item, event)}
              disabled={simulation.scenarios.length <= 1}
            />
          </Stack>
        </div>
      )}

      {/*  {showAdditionalDiv && showDelete && (
        <>
          <div style={{ textAlign: 'center' }}>
            <hr />
            <Typography tag="textextrasmall">
              Are you sure you want to remove the scenario?
            </Typography>
            <div style={{ marginTop: 10 }}>
              <Stack direction="row" gap={10}>
                <Button
                  type="secondary"
                  size="small"
                  onClick={(event) => handleDelete(false, event)}
                >
                  Cancel
                </Button>
                <Button
                  type="secondary"
                  size="small"
                  onClick={(event) => handleDeleteAction(item, event)}
                >
                  Remove
                </Button>
              </Stack>
            </div>
          </div>
        </>
      )}

      {showAdditionalDiv && item.description && (
        <div
          id="scenario-description"
          style={{ color: '#999999', fontSize: 11 }}
          className="text-container"
        >
          {item.description}
        </div>
      )} */}
    </Scenario>
  );
};

export default ScenarioListItem;
