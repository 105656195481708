import { ScenarioSettings as TScenarioSettings } from '@/store/types';

const handleInputChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setForm: React.Dispatch<React.SetStateAction<TScenarioSettings>>,
  parameterIndex?: number,
) => {
  const { id, value, type, checked } = event.target as HTMLInputElement & {
    id: string;
    value: string;
    type: string;
    checked: boolean;
  };

  const parts = id.split('___');
  const modified_id = parts.length > 1 ? parts[0] : id;

  console.log(modified_id);

  const updateForm = (newValue: unknown) => {
    if (parameterIndex === undefined) {
      setForm((prevForm) => ({
        ...prevForm,
        [modified_id]: newValue,
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        parameters: prevForm.parameters.map((param, index) => {
          if (index === parameterIndex) {
            return {
              ...param,
              [modified_id]: newValue,
            };
          }
          return param;
        }),
      }));
    }
  };

  if (type === 'checkbox') {
    updateForm(checked);
  } else if (
    modified_id === 'DropDown_JobsPrioritisation' ||
    modified_id === 'Variable_WaitOnBatchTimeAsProcessTimePercentage_value' ||
    modified_id === 'Fixed_WaitOnBatchTimeAsFixedHours_value' ||
    type === 'range'
  ) {
    updateForm(Number(value));
  } else if (modified_id === 'DropDown_WaitOnBatchTime') {
    if (value === 'Fixed_WaitOnBatchTimeAsFixedHours') {
      setForm((prevForm) => ({
        ...prevForm,
        parameters: prevForm.parameters.map((param, index) => {
          if (index === parameterIndex) {
            return {
              ...param,
              Fixed_WaitOnBatchTimeAsFixedHours: true,
              Variable_WaitOnBatchTimeAsProcessTimePercentage: false,
            };
          }
          return param;
        }),
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        parameters: prevForm.parameters.map((param, index) => {
          if (index === parameterIndex) {
            return {
              ...param,
              Fixed_WaitOnBatchTimeAsFixedHours: false,
              Variable_WaitOnBatchTimeAsProcessTimePercentage: true,
            };
          }
          return param;
        }),
      }));
    }
  } else {
    updateForm(value);
  }
};

export { handleInputChange };
