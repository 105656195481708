/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppDispatch, RootState } from '@/store';
import {
  OperationResource,
  OperationTree,
  ProcessTypesTree,
  ResourceTree,
  SiteTree,
  TSimulationMenuOptions,
  TreeItem,
  WipItemParams,
  WipTree,
  WipTreeElement,
  WorkPlanTree,
} from '@/store/types';
import { formatHoursToTime } from '@/utils/helpers';
import {
  Button,
  ButtonIcon,
  Input,
  Label,
  Table,
  Tooltip,
  useDebounceCallback,
} from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { TAnchorEl } from '@data-products-and-ai/react-components/lib/components/Layout/Menu/Menu';
import { ReactNode, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextMenu } from '../ContextMenu';

import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { changeExpanded } from '@/store/storeSlice';
import { produce } from 'immer';

interface ProductTreeRender extends WipItemParams {
  BATCH_BLK_SUFFIX: TreeItem;
  MAX_QUANTITY_IN_BATCH: TreeItem;
  OPERATION: TreeItem;
  OPERATION_BUSINESS: TreeItem;
  RESOURCES: ReactNode;
  OPERATION_ID: TreeItem;
  OPERATION_TYPE: TreeItem;
  PERSONNEL_TIME: TreeItem;
  PROCESS: TreeItem;
  PROCESS_TIME: TreeItem;
  SETUP_TIME: TreeItem;
  OPERATION_TYPE_ID: TreeItem;
  SET_OF_ADDITIONAL_EQUIPMENT_ID: TreeItem;
  SET_OF_OPERATION_QUALIFICATION_ID: TreeItem;
  SET_OF_WORK_UNIT_ID: TreeItem;
  SITE: TreeItem;
  SITE_ABBREVIATION: TreeItem;
  SITE_ID: TreeItem;
  STEP: TreeItem;
  WORK_PLAN: TreeItem;
  WORK_PLAN_ID: TreeItem;
}

type ProductTreeRenderGeneric = {
  [K in keyof ProductTreeRender]: string | number | ReactNode;
};

type TProductStructure = {
  id: string;
  data: ProductTreeRenderGeneric;
  children: TProductStructure[];
  isClickable?: boolean;
  isExpanded?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  isSelectable?: boolean;
  area: keyof WipTree;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Operations = () => {
  /* const dispatch: AppDispatch = useDispatch(); */
  const { setSimulationParams } = useContext(SimulationCtx);
  const dispatch: AppDispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<TAnchorEl>(null);
  const [selectedRow, setSelectedRow] = useState<TableRowData | null>(null);

  const handleInputChangeDebounced = useDebounceCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFilterQuery(event.target.value);
      console.log(event.target.value);
    },
    1000,
  );
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected === true),
  );

  const [filterQuery, setFilterQuery] = useState<string>('');

  const handleCustomDrawer = (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>,
    formItem: string,
    formArea: TSimulationMenuOptions,
  ) => {
    event.stopPropagation();

    setSimulationParams(
      produce((draft) => {
        draft.formItem = formItem;
        draft.formArea = formArea;
        draft.drawerOpen = true;
      }),
    );
  };

  const handleResources = (
    operation_id: string,
    operation_resources: OperationResource[],
  ) => {
    /*  const resources = operation_resources.map(
      (id) => selectedScenario?.data.resources.byId[id.RESOURCE_ID.value],
    ) as ResourceTree[]; */

    const c = operation_resources.map(
      (item: OperationResource, idx: number) => {
        const resource =
          selectedScenario?.data.resources.byId[item.RESOURCE_ID.value];

        const isLastItem = idx === operation_resources.length - 1;
        const borderBottomStyle = isLastItem
          ? {}
          : { borderBottom: 'solid 1px #dadada' };

        if (!resource) return;
        return (
          <div
            key={idx}
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 50px 50px',
              padding: 5,
              gap: 20,
              ...borderBottomStyle,
            }}
          >
            <div style={{ paddingRight: 10 }}>
              <Label
                labelDisplayStyle="underline"
                tooltipMessage={
                  selectedScenario?.data.resource_types.byId[
                    resource.RESOURCE_TYPE_ID.value
                  ].RESOURCE_TYPE.value
                }
              >
                {resource.RESOURCE_NAME.value}
              </Label>
            </div>
            <div>
              {
                selectedScenario?.data.shift_calendar.byId[
                  resource.RESOURCE_SHIFT_CALENDAR_ID.value
                ].SHIFT_CALENDAR.value
              }
            </div>
            <div style={{ paddingRight: 10, textAlign: 'center' }}>
              {item.RESOURCE_PRIORITY.value}
            </div>
          </div>
        );
      },
    );

    return (
      <>
        <div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 50px 50px',
              padding: 5,
              gap: 20,

              borderBottom: 'solid 1px #dadada',
              fontSize: 11,
              fontWeight: 'bold',
            }}
          >
            <div>Resource</div>
            <div>Shift</div>
            <div style={{ textAlign: 'center' }}>Priority</div>
          </div>
          {c}
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Button
              type="secondary"
              width={'100%'}
              onClick={(event) =>
                handleCustomDrawer(event, operation_id, 'operations_resources')
              }
            >
              Manage resources
            </Button>
          </div>
        </div>
      </>
    );
  };

  type TResourceCount = {
    item: OperationTree;
    is_open: boolean;
  };
  const ResourceCount = ({ item, is_open }: TResourceCount) => {
    if (!is_open) return '';

    const filteredObjects: OperationResource[] = selectedScenario
      ? selectedScenario.data.operations_resources.allIds
          .map((id) => selectedScenario?.data.operations_resources.byId[id])
          .filter((obj) => obj.OPERATION_ID.value === item.OPERATION_ID.value)
          .sort(
            (a, b) =>
              parseInt(a.RESOURCE_PRIORITY.value) -
              parseInt(b.RESOURCE_PRIORITY.value),
          )
      : [];

    return (
      <>
        <Tooltip
          key={'Tooltip_Resources_' + item.OPERATION_ID}
          color="light"
          title={handleResources(item.OPERATION_ID.value, filteredObjects)}
          disableInteractive={false}
        >
          <div style={{ display: 'inline-block' }}>
            <Button type="headless" allCaps={false}>
              {filteredObjects?.length}
            </Button>
          </div>
        </Tooltip>
      </>
    );
  };

  const TransformProduct = (item: OperationTree) => {
    const newItem: ProductTreeRenderGeneric = {
      key: item.OPERATION.originalValue,
      title: item.OPERATION.value,
      is_wip: item.is_wip,
      is_deleted: item.is_deleted,
      is_disabled: item.is_disabled,
      is_clicked: item.is_clicked,
      is_selectable: item.is_selectable,
      is_selected: item.is_selected,
      is_hidden: item.is_hidden,
      is_match: item.is_match,
      is_open: item.is_open,
      parent: '',
      children: [],
      BATCH_BLK_SUFFIX: '',
      MAX_QUANTITY_IN_BATCH: item.MAX_QUANTITY_IN_BATCH.value,
      STEP: item.STEP.value,
      OPERATION: item.STEP.value + '. ' + item.OPERATION.value,
      OPERATION_BUSINESS: item.OPERATION_BUSINESS.value,
      RESOURCES: selectedScenario && (
        <ResourceCount
          is_open={
            selectedScenario.data.work_plans.byId[item.WORK_PLAN_ID.value]
              .is_open
          }
          item={item}
        />
      ),
      /* 
         +
        JSON.stringify(resources.map((item) => item?.RESOURCE_NAME.value)) */ OPERATION_ID:
        '',
      OPERATION_TYPE: item.OPERATION_TYPE.value,
      OPERATION_TYPE_ID: item.OPERATION_TYPE_ID.value,
      PERSONNEL_TIME: formatHoursToTime(parseFloat(item.PERSONNEL_TIME.value))
        .time,
      PROCESS: '',
      PROCESS_TIME: formatHoursToTime(parseFloat(item.PROCESS_TIME.value)).time,
      SETUP_TIME: formatHoursToTime(parseFloat(item.SETUP_TIME.value)).time,
      SET_OF_ADDITIONAL_EQUIPMENT_ID: '',
      SET_OF_OPERATION_QUALIFICATION_ID: '',
      SET_OF_WORK_UNIT_ID: '',
      SITE: '',
      SITE_ABBREVIATION: '',
      SITE_ID: '',
      WORK_PLAN: '',
      WORK_PLAN_ID: '',
    };

    return newItem;
  };

  const generateObjectWithChildren = (
    sitesObject: WipTreeElement<SiteTree>,
    processTypesObject: WipTreeElement<ProcessTypesTree>,
    workPlansObject: WipTreeElement<WorkPlanTree>,
    originalObject: WipTreeElement<OperationTree>,
  ): TProductStructure[] => {
    const objectWithChildren: any[] = [];
    const itemMap: { [itemId: string]: TProductStructure } = {};

    sitesObject.allIds.forEach((itemId: string) => {
      const item = sitesObject.byId[itemId];

      const newItem: TProductStructure = {
        id: item.SITE_ID.originalValue,
        area: 'sites',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.SITE_ID.originalValue,
          title: item.SITE.value,
          is_wip: item.is_wip,
          is_deleted: item.is_deleted,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          children: [],
          BATCH_BLK_SUFFIX: '',
          MAX_QUANTITY_IN_BATCH: '',
          OPERATION: item.SITE.value,
          OPERATION_BUSINESS: '',
          RESOURCES: '',
          OPERATION_ID: '',
          OPERATION_TYPE: '',
          OPERATION_TYPE_ID: '',
          PERSONNEL_TIME: '',
          PROCESS: '',
          PROCESS_TIME: '',
          SETUP_TIME: '',
          SET_OF_ADDITIONAL_EQUIPMENT_ID: '',
          SET_OF_OPERATION_QUALIFICATION_ID: '',
          SET_OF_WORK_UNIT_ID: '',
          SITE: '',
          SITE_ABBREVIATION: '',
          SITE_ID: '',
          STEP: '',
          WORK_PLAN: '',
          WORK_PLAN_ID: '',
        },
        children: [],
      };

      objectWithChildren.push(newItem);
      itemMap[itemId] = newItem;
    });

    processTypesObject.allIds.forEach((itemId: string) => {
      const item = processTypesObject.byId[itemId];
      const siteId = item.SITE_ID.value;

      if (!sitesObject.byId[siteId]) return;

      const newItem: TProductStructure = {
        id: item.PROCESS_TYPE_ID.originalValue,
        area: 'process_types',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.PROCESS_TYPE_ID.originalValue,
          title: item.PROCESS.value,
          is_wip: item.is_wip,
          is_deleted: item.is_deleted,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          children: [],
          BATCH_BLK_SUFFIX: '',
          MAX_QUANTITY_IN_BATCH: '',
          OPERATION: item.PROCESS.value,
          OPERATION_BUSINESS: '',
          RESOURCES: '',
          OPERATION_TYPE_ID: '',
          OPERATION_ID: '',
          OPERATION_TYPE: '',
          PERSONNEL_TIME: '',
          PROCESS: '',
          PROCESS_TIME: '',
          SETUP_TIME: '',
          SET_OF_ADDITIONAL_EQUIPMENT_ID: '',
          SET_OF_OPERATION_QUALIFICATION_ID: '',
          SET_OF_WORK_UNIT_ID: '',
          SITE: '',
          SITE_ABBREVIATION: '',
          SITE_ID: '',
          STEP: '',
          WORK_PLAN: '',
          WORK_PLAN_ID: '',
        },
        children: [],
      };

      objectWithChildren
        .find((obj) => obj.id === siteId)
        .children.push(newItem);
      // itemMap[siteId].children.push(newItem);

      // Store the item in the map for later reference
      itemMap[itemId] = newItem;
    });

    originalObject.allIds.forEach((itemId: string) => {
      const item = originalObject.byId[itemId];

      const siteId = item.SITE_ID.originalValue;
      if (!sitesObject.byId[siteId]) return;

      const processTypeId = item.PROCESS_TYPE_ID.value;
      const wp = workPlansObject.byId[item.WORK_PLAN_ID.value];
      const newItem: TProductStructure = {
        id: item.WORK_PLAN_ID.value,
        area: 'work_plans',
        isExpanded: wp.is_open,
        isDisabled: wp.is_disabled,
        data: {
          key: item.WORK_PLAN_ID.value,
          title: item.WORK_PLAN_ID.value,
          is_wip: wp.is_wip,
          is_deleted: wp.is_deleted,
          is_disabled: wp.is_disabled,
          is_clicked: wp.is_clicked,
          is_selectable: wp.is_selectable,
          is_selected: wp.is_selected,
          is_hidden: wp.is_hidden,
          is_match: wp.is_match,
          is_open: wp.is_open,
          parent: '',
          children: [],
          BATCH_BLK_SUFFIX: '',
          MAX_QUANTITY_IN_BATCH: '',
          OPERATION: wp.WORK_PLAN.value,

          OPERATION_BUSINESS: '',
          RESOURCES: '',
          OPERATION_ID: '',
          OPERATION_TYPE_ID: '',
          OPERATION_TYPE: '',
          PERSONNEL_TIME: wp.is_open && (
            <div style={{ opacity: 0.5 }}>
              <ButtonIcon
                icon="IconSort"
                size="extra-small"
                tooltipMessage="Change steps order"
                onClick={(event) =>
                  handleCustomDrawer(
                    event,
                    item.WORK_PLAN_ID.value,
                    'operation_steps',
                  )
                }
              />
            </div>
          ),
          PROCESS: '',
          PROCESS_TIME: '',
          SETUP_TIME: '',
          SET_OF_ADDITIONAL_EQUIPMENT_ID: '',
          SET_OF_OPERATION_QUALIFICATION_ID: '',
          SET_OF_WORK_UNIT_ID: '',
          SITE: '',
          SITE_ABBREVIATION: '',
          SITE_ID: '',
          STEP: item.STEP.value,
          WORK_PLAN: '',
          WORK_PLAN_ID: '',
        },
        children: [],
      };

      //console.log(newItem);
      const isDuplicate = itemMap[processTypeId].children.some(
        (child) => child.id === newItem.id,
      );

      if (!isDuplicate) {
        itemMap[processTypeId].children.push(newItem);
        itemMap[item.WORK_PLAN_ID.value] = newItem;
      }
    });

    originalObject.allIds.forEach((itemId: string) => {
      const item = originalObject.byId[itemId];
      const workPlanId = item.WORK_PLAN_ID.value;

      const siteId = item.SITE_ID.originalValue;
      if (!sitesObject.byId[siteId]) return;

      const newItem: TProductStructure = {
        id: item.OPERATION_ID.value,
        area: 'operations',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: TransformProduct(item),
        children: [],
      };

      if (!newItem.data.is_deleted) {
        itemMap[workPlanId].children.push(newItem);

        itemMap[workPlanId].children.sort((a, b) => {
          const stepA = (a.data.STEP as number) || 0; // Use 0 as a default value if STEP.value is null or undefined
          const stepB = (b.data.STEP as number) || 0; // Use 0 as a default value if STEP.value is null or undefined

          return stepA - stepB;
        });

        itemMap[itemId] = newItem;
      }
    });

    return objectWithChildren;
  };

  if (!selectedScenario) return <>No Scenario</>;

  const objectWithChildren = generateObjectWithChildren(
    selectedScenario.data.sites,
    selectedScenario.data.process_types,
    selectedScenario.data.work_plans,
    selectedScenario.data.operations,
  );

  const TableHeaders: TableHeaderType[] = [
    {
      title: 'Operation',
      align: 'left',
      columnKey: 'OPERATION',
      columnWidth: '400px',
    },
    {
      title: 'Operation Business ID',
      align: 'left',
      columnKey: 'OPERATION_BUSINESS',
    },
    {
      title: 'Resources',
      align: 'center',
      columnKey: 'RESOURCES',
    },
    {
      title: 'Max Quantity in Batch',
      align: 'center',
      columnKey: 'MAX_QUANTITY_IN_BATCH',
    },
    {
      title: 'Operation Type',
      align: 'center',
      columnKey: 'OPERATION_TYPE',
    },
    { title: 'Setup Time', align: 'right', columnKey: 'SETUP_TIME' },
    { title: 'Process Time', align: 'right', columnKey: 'PROCESS_TIME' },
    { title: 'Personnel Time', align: 'right', columnKey: 'PERSONNEL_TIME' },
  ];

  const handleDoubleClick = (value: TableRowData) => {
    const area = value.area as TSimulationMenuOptions;
    if (area !== 'operations') return;

    setSimulationParams((prevState) => ({
      ...prevState,
      drawerOpen: true,
      formItem: value.id,
      formArea: area,
    }));
  };

  const handleClick = (value: TableRowData) => {
    if (!value.children) return;
    if (value.children.length <= 0) return;

    dispatch(
      changeExpanded({
        id: value.id,
        isExpanded: value.isExpanded ?? false,
        area: value.area as keyof WipTree,
      }),
    );
  };

  const handleMenuRight = (
    value: TableRowData,
    event?: React.MouseEvent<HTMLTableRowElement>,
  ) => {
    const area = value.area as keyof WipTree;

    if (area !== 'operations' && area !== 'work_plans') return;

    event &&
      setAnchorEl({
        left: event.clientX,
        top: event.clientY,
      });

    setSelectedRow(value);
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Input
          id="Filter"
          onChange={handleInputChangeDebounced}
          placeholder="Filter"
        />
      </div>

      <ContextMenu
        selectedRow={selectedRow}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        showRemove={selectedRow?.area === 'work_plans' ? false : true}
      />
      <Table
        headers={TableHeaders}
        width={'100%'}
        isSelectable={false}
        hasFilter={true}
        filterQuery={filterQuery}
        rows={objectWithChildren}
        typographyTag="textsmall"
        pointerCursor={true}
        onRowDoubleClick={(value) => handleDoubleClick(value)}
        onRowClick={(value) => handleClick(value)}
        onRowRightClick={(value, event) => handleMenuRight(value, event)}
        stickyHeader={true}
        headerVerticalAlign="bottom"
      />

      {/* {objectWithChildren.map((item, index) => {
        return <Line key={index} id={item.PRODUCT_ID} />;
      })} */}

      {/*    {JSON.stringify(
        simulation.scenarios.filter((item) => item.is_selected === true)
      )} */}
    </>
  );
};

export default Operations;
