import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { LoadWip } from '@/pages/LoadWip';
import { Button } from '@data-products-and-ai/react-components';
import { useContext } from 'react';

const SimulationNotStarted = () => {
  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  return (
    <>
      <div style={{ padding: 20 }}>
        <Button
          width="100%"
          /*   disabled={!simulationParams.factoryDataLoaded} */
          onClick={() =>
            setSimulationParams((prevState) => ({
              ...prevState,
              simulationForm: true,
            }))
          }
        >
          Create Simulation
        </Button>
      </div>
      <div style={{ margin: 0, borderBottom: 'solid 1px #ededed' }} />
      <div style={{ position: 'relative', padding: 20 }}>
        <Button
          type="secondary"
          width="100%"
          onClick={() => {
            document.getElementById('library-images')?.click();
          }}
        >
          Upload Factory Data
        </Button>
        <input
          type="file"
          name="library-images"
          id="library-images"
          accept="application/vnd.ms-excel"
          hidden
          onChange={(e) => console.log(e)}
        />
      </div>
      <div style={{ margin: 0, borderBottom: 'solid 1px #ededed' }} />
    </>
  );
};

export default SimulationNotStarted;
