import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { TSimulationMenuOptions } from '@/store/types';
import { useContext } from 'react';
import { ProductionOrders } from './ProductionOrders';
import { Products } from './Products';
import { ScenarioSettings } from './ScenarioSettings';
import { Operations } from './Operations';
import { Shifts } from './Shifts';
import { Shutdowns } from './Shutdowns';
import { Resources } from './Resources';

const ShowTable = (selectedOption: TSimulationMenuOptions) => {
  switch (selectedOption) {
    case 'scenario_settings':
      return <ScenarioSettings />;
    case 'products':
      return <Products />;
    case 'production_orders':
      return <ProductionOrders />;
    case 'operations':
      return <Operations />;
    case 'shifts':
      return <Shifts />;
    case 'resources':
      return <Resources />;
    case 'shutdowns':
      return <Shutdowns />;
    default:
      return <ProductionOrders />;
  }
};
const TableView = () => {
  const { simulationParams } = useContext(SimulationCtx);

  return (
    <div
      style={{
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      {ShowTable(simulationParams.simulationOptionSelected)}
    </div>
  );
};

export default TableView;
