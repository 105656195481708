import { Stack, Input } from '@data-products-and-ai/react-components';
import { useEffect, useState } from 'react';

type TOperationsTimePicker = {
  id: string;
  hours?: number;
  minutes?: number;
  seconds?: number;
  onChange?: (value: number) => void;
};

const OperationsTimePicker = ({
  id,
  hours = 0,
  minutes = 0,
  seconds = 0,
  onChange,
}: TOperationsTimePicker) => {
  const [pickerHours, setPickerHours] = useState(hours);
  const [pickerMinutes, setPickerMinutes] = useState(minutes);
  const [pickerSeconds, setPickerSeconds] = useState(seconds);

  const handleInputChange = (inputType: string, value: number) => {
    if (!value) value = 0;

    if (inputType === 'hours') {
      setPickerHours(value);
    } else if (inputType === 'minutes') {
      setPickerMinutes(value);
    } else if (inputType === 'seconds') {
      setPickerSeconds(value);
    }
  };

  useEffect(() => {
    const combinedValue =
      pickerHours * 3600 + pickerMinutes * 60 + pickerSeconds;

    if (onChange) {
      onChange(combinedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerHours, pickerMinutes, pickerSeconds]);

  return (
    <Stack direction="row" gap={20}>
      <Input
        id={`${id}_time_hours`}
        textAfter="Hours"
        size="small"
        defaultValue={hours.toString()}
        onChange={(event) =>
          handleInputChange('hours', parseInt(event.target.value))
        }
      />
      <Input
        id={`${id}_time_minutes`}
        textAfter="Minutes"
        size="small"
        defaultValue={minutes.toString()}
        onChange={(event) =>
          handleInputChange('minutes', parseInt(event.target.value))
        }
      />
      <Input
        id={`${id}_time_seconds`}
        textAfter="Seconds"
        size="small"
        defaultValue={seconds.toString()}
        onChange={(event) =>
          handleInputChange('seconds', parseInt(event.target.value))
        }
      />
    </Stack>
  );
};

export default OperationsTimePicker;
