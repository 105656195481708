import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { useContext } from 'react';
import SimulationNotStarted from './SimulationNotStarted/SimulationNotStarted';
import SimulationStarted from './SimulationStarted/SimulationStarted';

const LeftMenu = () => {
  const { simulationParams } = useContext(SimulationCtx);

  return simulationParams.simulationStarted ? (
    <SimulationStarted />
  ) : (
    <>
      <SimulationNotStarted />
    </>
  );
};

export default LeftMenu;
