import { MessageSwitcherProps } from '@/components/Loader/Loader';
import { TSimulationMenuOptions } from '@/store/types';
import { ReactNode, createContext, useState } from 'react';

export type SimulationParamsType = {
  factoryDataLoaded: boolean | null;
  simulationStarted: boolean;
  simulationForm: boolean;
  simulationOptionSelected: TSimulationMenuOptions;
  drawerOpen: boolean;
  formItem?: string;
  formArea?: TSimulationMenuOptions;
  loaderMessage: string | MessageSwitcherProps;
  selectedSites: string[];
};

const defaultSimulationParams: SimulationParamsType = {
  factoryDataLoaded: null,
  simulationStarted: false,
  simulationForm: false,
  simulationOptionSelected: 'scenario_settings',
  drawerOpen: false,
  formItem: undefined,
  loaderMessage: '',
  selectedSites: [],
};

export const SimulationCtx = createContext<{
  simulationParams: SimulationParamsType;
  setSimulationParams: React.Dispatch<
    React.SetStateAction<SimulationParamsType>
  >;
}>({
  simulationParams: defaultSimulationParams,
  setSimulationParams: () => {},
});

type SimulationProviderType = {
  children: ReactNode;
};
export const SimulationProvider = ({ children }: SimulationProviderType) => {
  const [simulationParams, setSimulationParams] =
    useState<SimulationParamsType>(defaultSimulationParams);

  return (
    <SimulationCtx.Provider value={{ simulationParams, setSimulationParams }}>
      {children}
    </SimulationCtx.Provider>
  );
};
