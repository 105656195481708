import {
  ScenarioSettingsParameters,
  ScenarioSettings as TScenarioSettings,
} from '@/store/types';
import {
  ButtonIcon,
  Checkbox,
  Label,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
  useDebounceCallback,
} from '@data-products-and-ai/react-components';

import { handleInputChange } from '../../helpers';
import styles from './Parameters.module.scss';

const JobsPrioritisation = [
  {
    key: 2,
    value: 'First In First Out',
  },
  {
    key: 3,
    value: 'Slack Coefficient',
  },
  {
    key: 4,
    value: 'Due Date',
  },
  {
    key: 5,
    value: 'Shortest Processing Time',
  },
  {
    key: 6,
    value: 'Longest Processing Time',
  },
] as const;

type TParameters = {
  parameters: ScenarioSettingsParameters;
  setForm: React.Dispatch<React.SetStateAction<TScenarioSettings>>;
  parameterIndex: number;
  totalSetParameters: number;
};

const Parameters = (props: TParameters) => {
  const handleInputChangeDebounced = useDebounceCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleInputChange(event, props.setForm, props.parameterIndex);
    },
    1000,
  );

  const handleDuplicate = () => {
    /*   const newParameters = [...prevForm.parameters];  
    newParameters.push({ [id]: newValue }); // Push a new object to the parameters array   */

    props.setForm((prevForm) => ({
      ...prevForm,
      parameters: [...prevForm.parameters, props.parameters],
    }));
  };

  const handleDelete = (indexNumber: number) => {
    props.setForm((prevForm) => ({
      ...prevForm,
      parameters: [
        ...prevForm.parameters.filter((_param, index) => index !== indexNumber),
      ],
    }));
  };

  return (
    <>
      <div className={styles.ParametersContainer}>
        <div
          style={{ backgroundColor: '#FAFAFA', padding: 10, borderRadius: 8 }}
        >
          <Typography tag="textsmall_strong">
            Parameters Set #{props.parameterIndex + 1}
          </Typography>

          <div className={styles.Operations}>
            <Stack direction="row" gap={5}>
              <ButtonIcon
                icon="IconCopy"
                size="extra-small"
                tooltipMessage="Duplicate"
                onClick={() => handleDuplicate()}
              />
              <ButtonIcon
                icon="IconClose"
                size="extra-small"
                tooltipMessage={
                  props.totalSetParameters > 1 ? 'Remove' : undefined
                }
                onClick={() => handleDelete(props.parameterIndex)}
                disabled={props.totalSetParameters <= 1}
              />
            </Stack>
          </div>
        </div>

        <div style={{ padding: 20 }}>
          <Label
            tag="textsmall_strong"
            marginTop={0}
            tooltipMessage="To what should the simulation give priority to?"
          >
            Jobs Prioritisation
          </Label>
          <Typography tag="textsmall">
            <RadioGroup
              gap={20}
              id={`DropDown_JobsPrioritisation____${props.parameterIndex}`}
              orientation="row"
              onChange={(event) =>
                handleInputChange(event, props.setForm, props.parameterIndex)
              }
            >
              {JobsPrioritisation.map((item, index) => (
                <Radio
                  key={index}
                  label={item.value}
                  name={`DropDown_JobsPrioritisation____${props.parameterIndex}`}
                  value={item.key.toString()}
                  isChecked={
                    item.key === props.parameters.DropDown_JobsPrioritisation
                  }
                />
              ))}
            </RadioGroup>
          </Typography>

          <Label
            tag="textsmall_strong"
            marginTop={15}
            tooltipMessage="Define if Batch Time should be in hours or percentage"
          >
            Wait on Batch Time
          </Label>
          <Typography tag="textsmall">
            <RadioGroup
              gap={20}
              id={`DropDown_WaitOnBatchTime____${props.parameterIndex}`}
              orientation="row"
              onChange={(event) =>
                handleInputChange(event, props.setForm, props.parameterIndex)
              }
            >
              <Radio
                key="Wait_1"
                label="Wait on batch time as fixed hours"
                name={`DropDown_WaitOnBatchTime____${props.parameterIndex}`}
                value="Fixed_WaitOnBatchTimeAsFixedHours"
                isChecked={props.parameters.Fixed_WaitOnBatchTimeAsFixedHours}
              />
              <Radio
                key="Wait_2"
                label="Wait on batch time as process time percentage"
                name={`DropDown_WaitOnBatchTime____${props.parameterIndex}`}
                value="Variable_WaitOnBatchTimeAsProcessTimePercentage"
                isChecked={
                  props.parameters
                    .Variable_WaitOnBatchTimeAsProcessTimePercentage
                }
              />
            </RadioGroup>
          </Typography>

          {props.parameters.Variable_WaitOnBatchTimeAsProcessTimePercentage ? (
            <>
              <Label tag="textsmall_strong" marginTop={15}>
                Percentage
              </Label>

              <Slider
                id={`Variable_WaitOnBatchTimeAsProcessTimePercentage_value____${props.parameterIndex}`}
                min={0}
                max={100}
                valueSufix="%"
                typographyColor="#666666"
                showValue
                defaultValue={
                  props.parameters
                    .Variable_WaitOnBatchTimeAsProcessTimePercentage_value
                }
                onChange={(event) => handleInputChangeDebounced(event)}
              />
            </>
          ) : (
            <>
              <Label tag="textsmall_strong" marginTop={15}>
                Number of hours
              </Label>

              <Slider
                id={`Fixed_WaitOnBatchTimeAsFixedHours_value____${props.parameterIndex}`}
                min={1}
                max={96}
                valueSufix=" hours"
                typographyColor="#666666"
                showValue
                defaultValue={
                  props.parameters.Fixed_WaitOnBatchTimeAsFixedHours_value
                }
                onChange={(event) => handleInputChangeDebounced(event)}
              />
            </>
          )}

          <Label tag="textsmall_strong" marginTop={15}>
            Other Parameters
          </Label>
          <Typography tag="textsmall">
            <Stack direction="row" gap={20}>
              <Checkbox
                id={`DropDown_UseEachPOStartDate____${props.parameterIndex}`}
                label="Use each PO Start Date"
                value="true"
                isChecked={props.parameters.DropDown_UseEachPOStartDate}
                onChange={(event) =>
                  handleInputChange(event, props.setForm, props.parameterIndex)
                }
              />
              <Checkbox
                id={`DropDown_UseOfAdditionalEquipment____${props.parameterIndex}`}
                label="Additional Equipment"
                value="true"
                isChecked={props.parameters.DropDown_UseOfAdditionalEquipment}
                onChange={(event) =>
                  handleInputChange(event, props.setForm, props.parameterIndex)
                }
              />
            </Stack>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Parameters;
