import { AppDispatch, RootState } from '@/store';
import { TDuplicateType, TSimulationMenuOptions, WipTree } from '@/store/types';
import {
  Backdrop,
  Button,
  ButtonIcon,
  DatePicker,
  Label,
  Menu,
  MenuOption,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { TableRowData } from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { TAnchorEl } from '@data-products-and-ai/react-components/lib/components/Layout/Menu/Menu';
import { useDispatch, useSelector } from 'react-redux';

import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import {
  deleteRow,
  disableRow,
  duplicateItem,
  duplicateRow,
} from '@/store/storeSlice';
import React, { useContext, useEffect, useState } from 'react';

type TContextMenu = {
  anchorEl: TAnchorEl;
  selectedRow: TableRowData | null;
  onClose?: () => void;
  showRemove?: boolean;
  showDuplicate?: boolean;
  showDelete?: boolean;
  showEdit?: boolean;
};

type TDuplicate = {
  num: number;
  start_date: number;
  end_date: number;
  duplicateType: TDuplicateType;
};

const ContextMenu = ({
  anchorEl,
  selectedRow,
  onClose,
  showRemove = true,
  showDelete = true,
  showDuplicate = true,
  showEdit = true,
}: TContextMenu) => {
  const initialDuplicate: TDuplicate = {
    num: 1,
    start_date: 0,
    end_date: 0,
    duplicateType: 'Even',
  };

  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected === true),
  );
  const open = Boolean(anchorEl);

  const [duplicatedMany, setDuplicatedMany] = useState<boolean>(false);
  const [duplicateObj, setDuplicateObj] =
    useState<TDuplicate>(initialDuplicate);
  const { setSimulationParams } = useContext(SimulationCtx);

  const handleInputChange = (
    value: number | string | null,
    key: keyof TDuplicate,
  ) => {
    setDuplicateObj((prevForm) => ({
      ...prevForm,
      [key]: value,
    }));
  };

  useEffect(() => {
    setDuplicatedMany(false);

    if (!selectedRow) return;
    if (selectedRow.area === 'production_orders') {
      setDuplicateObj({
        num: 1,
        start_date:
          selectedScenario && selectedRow
            ? selectedScenario?.data.production_orders.byId[selectedRow.id]
                .PO_START_DATE_TIMESTAMP.value
            : 0,
        end_date:
          selectedScenario && selectedRow
            ? selectedScenario?.data.production_orders.byId[selectedRow.id]
                .PO_END_DATE_TIMESTAMP.value
            : 0,
        duplicateType: 'Even',
      });
    }
    /*  setSimulationParams((prevState) => ({
      ...prevState,
      drawerOpen: true,
      formItem: selectedRow.id,
    })); */
  }, [anchorEl, selectedRow, selectedScenario]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setDuplicatedMany(false);
        handleMenuClose();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*   const handleMenuClick =
    (value: TableRowData) => (event: React.MouseEvent<HTMLElement>) => {
      console.log('ok');
      setAnchorEl(event.currentTarget);

      setSelectedRow(value);

      console.log(event.currentTarget.getBoundingClientRect());
      console.log(value);
    }; */

  const handleEditRow = () => {
    if (!selectedRow) return;

    setSimulationParams((prevState) => ({
      ...prevState,
      drawerOpen: true,
      formItem: selectedRow.id,
      formArea: selectedRow.area as TSimulationMenuOptions,
    }));
    /* dispatch(
      deleteRow({
        id: selectedRow.id,
        area: selectedRow.area as keyof WipTree,
      }),
    ); */
  };
  const handleDeleteRow = () => {
    if (!selectedRow) return;

    dispatch(
      deleteRow({
        id: selectedRow.id,
        area: selectedRow.area as keyof WipTree,
      }),
    );
  };
  const handleDisableRow = () => {
    if (!selectedRow) return;
    console.log({
      id: selectedRow.id,
      isDisabled: !selectedRow.isDisabled,
      area: selectedRow.area as keyof WipTree,
    });

    dispatch(
      disableRow({
        id: selectedRow.id,
        isDisabled: !selectedRow.isDisabled,
        area: selectedRow.area as keyof WipTree,
      }),
    );
  };
  /*   const handleDuplicateRow = () => {
    if (!selectedRow) return;
    console.log({
      id: selectedRow.id,
      area: selectedRow.area as keyof WipTree,
    });

    dispatch(
      duplicateRow({
        id: selectedRow.id,
        num: 1,
        area: selectedRow.area as keyof WipTree,
      }),
    );
  }; */
  const handleShowDuplicateManyRow = () => {
    if (!selectedRow) return;

    setDuplicatedMany(true);
  };
  const handleDuplicateManyRow = (obj: TDuplicate) => {
    if (!selectedRow) return;
    console.log({
      id: selectedRow.id,
      area: selectedRow.area as keyof WipTree,
    });

    dispatch(
      duplicateRow({
        id: selectedRow.id,
        num: obj.num,
        duplicateType: obj.duplicateType,
        intervalStart: obj.start_date,
        intervalEnd: obj.end_date,
        area: selectedRow.area as keyof WipTree,
      }),
    );
    handleMenuClose();
  };

  const handleDuplicateItem = () => {
    if (!selectedRow) return;
    console.log({
      id: selectedRow.id,
      area: selectedRow.area as keyof WipTree,
    });

    const selected_area = selectedRow.area as keyof WipTree;
    let id_key;

    if (selected_area === 'resources') id_key = 'RESOURCE_ID';
    if (selected_area === 'operations') id_key = 'OPERATION_ID';
    if (selected_area === 'shifts') id_key = 'SHIFT_ID';
    if (selected_area === 'shutdowns') id_key = 'SHUTDOWN_ID';
    if (selected_area === 'work_plans') id_key = 'WORK_PLAN_ID';

    if (id_key)
      dispatch(
        duplicateItem({
          id: selectedRow.id,
          id_key: id_key,
          area: selectedRow.area as keyof WipTree,
        }),
      );
    handleMenuClose();
  };

  const handleMenuClose = () => {
    //setDuplicatedMany(false);
    //setDuplicationNumber(1);
    onClose && onClose();
    //setAnchorEl(null);
  };

  return !duplicatedMany ? (
    <Menu
      open={open}
      onClose={handleMenuClose}
      anchorEl={anchorEl}
      closeOnClickOutside={!duplicatedMany}
    >
      {showEdit && (
        <MenuOption
          onClick={handleEditRow}
          onAfterClick={handleMenuClose}
          verticalPadding={5}
          horizontalPadding={15}
        >
          Edit
        </MenuOption>
      )}
      {/*   <MenuOption
            onClick={() => handleDuplicateRow()}
            onAfterClick={handleMenuClose}
            verticalPadding={5}
            horizontalPadding={15}
          >
            Duplicate
          </MenuOption> */}
      {showDuplicate && selectedRow && (
        <MenuOption
          onClick={
            selectedRow?.area === 'production_orders'
              ? () => handleShowDuplicateManyRow()
              : () => handleDuplicateItem()
          }
          verticalPadding={5}
          horizontalPadding={15}
        >
          Duplicate
        </MenuOption>
      )}
      {showRemove && (
        <MenuOption
          onClick={() => handleDisableRow()}
          onAfterClick={handleMenuClose}
          verticalPadding={5}
          horizontalPadding={15}
        >
          {selectedRow?.isDisabled ? 'Restore' : 'Remove'}
        </MenuOption>
      )}

      {selectedRow?.isDisabled && showDelete && (
        <MenuOption
          onClick={() => handleDeleteRow()}
          onAfterClick={handleMenuClose}
          verticalPadding={5}
          horizontalPadding={15}
        >
          <div style={{ color: '#FF0000' }}>Delete Permanently</div>
        </MenuOption>
      )}
    </Menu>
  ) : (
    selectedRow?.area === 'production_orders' && (
      <React.Fragment>
        <Backdrop backgroundColor="light" isShown />

        <div
          style={{
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'fixed',
            zIndex: 22000,
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #CCCCCC',
            borderRadius: 4,
            boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.15)',
            padding: 40,
          }}
        >
          <div style={{ position: 'absolute', top: 0, right: 0, opacity: 0.3 }}>
            <ButtonIcon
              icon="IconClose"
              size="extra-small"
              onClick={() => {
                setDuplicatedMany(false);
                handleMenuClose();
              }}
            />
          </div>

          <Stack gap={5}>
            <Typography tag="textsmall_strong">
              {selectedRow?.data.title}
            </Typography>
            <Typography tag="textsmall">
              Create {duplicateObj.num} duplication{duplicateObj.num > 1 && 's'}
            </Typography>

            <div style={{ padding: 15 }}>
              <Slider
                defaultValue={duplicateObj.num}
                min={1}
                max={50}
                width={300}
                showValue={false}
                onChange={(event) =>
                  handleInputChange(event.target.value, 'num')
                }
              />
            </div>

            <div>
              <Label>Distribute delivery date from:</Label>
              <Stack direction="row" gap={10}>
                <div style={{ width: 150 }}>
                  <DatePicker
                    id="Duplicate_StartDate"
                    size="small"
                    defaultValue={
                      selectedRow
                        ? duplicateObj.start_date /* selectedScenario?.data.production_orders.byId[
                            selectedRow.id
                          ].PO_START_DATE_TIMESTAMP.value */
                        : undefined
                    }
                    onChange={(value) => handleInputChange(value, 'start_date')}
                  />
                </div>
                <div style={{ width: 150 }}>
                  <DatePicker
                    id="Duplicate_EndDate"
                    size="small"
                    defaultValue={
                      selectedRow
                        ? duplicateObj.end_date /* selectedScenario?.data.production_orders.byId[
                            selectedRow.id
                          ].PO_END_DATE_TIMESTAMP.value */
                        : undefined
                    }
                    onChange={(value) => handleInputChange(value, 'end_date')}
                  />
                </div>
              </Stack>
            </div>
            <div style={{ padding: 10 }}>
              <Typography tag="textsmall">
                <RadioGroup
                  id="SpreadType"
                  orientation="row"
                  gap={20}
                  onChange={(event) =>
                    handleInputChange(event.target.value, 'duplicateType')
                  }
                >
                  <Radio
                    name="SpreadType"
                    isChecked={duplicateObj.duplicateType === 'Even'}
                    label="Evenly"
                    value="Even"
                  />

                  <Radio
                    name="SpreadType"
                    isChecked={duplicateObj.duplicateType === 'Random'}
                    label="Randomly"
                    value="Random"
                  />
                </RadioGroup>
              </Typography>
            </div>

            <Button
              type="secondary"
              width={200}
              onClick={() => handleDuplicateManyRow(duplicateObj)}
            >
              Confirm
            </Button>
          </Stack>
        </div>
      </React.Fragment>
    )
  );
};

export default ContextMenu;
