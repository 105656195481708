import {
  SimulationListGroupedItemType,
  SimulationListItemType,
} from '../types';

export function groupData(
  rows: SimulationListItemType[],
): SimulationListGroupedItemType[] {
  const groupedMap: Map<string, SimulationListGroupedItemType> = new Map();

  rows.forEach((row) => {
    const existingGroup = groupedMap.get(row.id);

    if (existingGroup) {
      existingGroup.startTime =
        row.startTime > existingGroup.startTime
          ? row.startTime
          : existingGroup.startTime;
      existingGroup.endTime =
        row.endTime > existingGroup.endTime
          ? row.endTime
          : existingGroup.endTime;
      existingGroup.retries += 1;
    } else {
      groupedMap.set(row.id, {
        id: row.id,
        startTime: row.startTime,
        endTime: row.endTime,
        title: row.title,
        description: row.description,
        progress: row.progress,
        retries: 0,
      });
    }
  });

  return Array.from(groupedMap.values());
}
