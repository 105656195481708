import { Wip } from '@/store/types';

export type WipCategoryType = {
  key: keyof Wip;
  label: string;
  endpoint: string;
  order: number; //This will order the categories on the top menu from the bigger number to the bottom number. If set to -1 will not be displayed in the menu
  hasSiteFilter: boolean;
};

const sufix = import.meta.env.VITE_VIEWS_SUFFIX;

const query = '';

//const query = '?SITE_ID=3&SITE_ID=2';
//const sufix = ''
//asdas;

export const WIP_CATEGORIES: WipCategoryType[] = [
  {
    key: 'sites',
    label: 'Sites',
    endpoint: 'tables/V_WIP_SITE' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },

  {
    key: 'production_orders',
    label: 'Production Orders',
    endpoint: 'tables/V_WIP_PRODUCTION_ORDER' + sufix + query,
    order: 100,
    hasSiteFilter: true,
  },
  {
    key: 'products',
    label: 'Products',
    endpoint: 'tables/V_WIP_PRODUCT' + sufix + query,
    order: 110,
    hasSiteFilter: true,
  },

  /* {
    key: 'additional_equipments',
    label: 'Additional equipment',
    endpoint: 'tables/V_WIP_ADDITIONAL_EQUIPMENT' + sufix + query,
    order: -1,
  }, */
  {
    key: 'operations',
    label: 'Processes',
    endpoint: 'tables/V_WIP_PROCESSES' + sufix + query,
    order: 90,
    hasSiteFilter: true,
  },
  {
    key: 'pauses',
    label: 'Pauses',
    endpoint: 'tables/V_WIP_PAUSE' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  /* {
    key: 'set_additional_equipments',
    label: 'Sets of additional equipment',
    endpoint: 'tables/V_WIP_SET_OF_ADDITIONAL_EQUIPMENT' + sufix + query,
    order: -1,
  }, */
  //https://nuax27mdn6.execute-api.eu-central-1.amazonaws.com/dev/tables/V_WIP_SHIFT?SHIFT_ID=4&SHIFT_CALENDAR_ID=210
  {
    key: 'set_pauses',
    label: 'Sets of pauses',
    endpoint: 'tables/V_WIP_SET_OF_PAUSE' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  /*  {
    key: 'set_work_units',
    label: 'Sets of work units',
    endpoint: 'tables/V_WIP_SET_OF_WORK_UNITS' + sufix + query,
    order: -1,
  }, */
  {
    key: 'shifts',
    label: 'Shifts',
    endpoint: 'tables/V_WIP_SHIFT' + sufix + query,
    order: 60,
    hasSiteFilter: true,
  },
  {
    key: 'shift_calendar',
    label: 'Shifts calendar',
    endpoint: 'tables/V_WIP_SHIFT_CALENDAR' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'shutdowns',
    label: 'Shutdowns',
    endpoint: 'tables/V_WIP_SHUTDOWN' + sufix + query,
    order: 50,
    hasSiteFilter: true,
  },
  {
    key: 'work_plans',
    label: 'Work plans',
    endpoint: 'tables/V_WIP_WORK_PLAN' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'work_units',
    label: 'Work Units',
    endpoint: 'tables/V_WIP_WORK_UNIT' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },

  {
    key: 'process_types',
    label: 'Process Types',
    endpoint: 'tables/V_WIP_PROCESS_TYPES' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },

  {
    key: 'operation_types',
    label: 'Operation Types',
    endpoint: 'tables/V_WIP_OPERATION_TYPES' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'resources',
    label: 'Resources',
    endpoint: 'tables/V_WIP_RESOURCES' + sufix + query,
    order: 80,
    hasSiteFilter: true,
  },
  {
    key: 'resource_names',
    label: 'Resources Names',
    endpoint: 'tables/V_WIP_RESOURCE_NAMES' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'resource_types',
    label: 'Resources Types',
    endpoint: 'tables/V_WIP_RESOURCE_TYPES' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'resource_classes',
    label: 'Resource Classes',
    endpoint: 'tables/V_WIP_RESOURCE_CLASSES' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'resource_sub_classes',
    label: 'Resource Subclasses',
    endpoint: 'tables/V_WIP_RESOURCE_SUBCLASSES' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'resource_grades',
    label: 'Resource Grades',
    endpoint: 'tables/V_WIP_RESOURCE_GRADES' + sufix,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'operations_resources',
    label: 'Operation Resources',
    endpoint: 'tables/V_WIP_OPERATIONS_RESOURCES' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'pause_shift',
    label: 'Resource Grades',
    endpoint: 'tables/V_WIP_PAUSE_SHIFT' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'enterprises',
    label: 'Enterprises',
    endpoint: 'tables/V_WIP_ENTERPRISE' + sufix,
    order: -1,
    hasSiteFilter: false,
  },
  {
    key: 'product_types',
    label: 'Product Types',
    endpoint: 'tables/V_WIP_PRODUCT_TYPE' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'product_classes',
    label: 'Product Classes',
    endpoint: 'tables/V_WIP_PRODUCT_CLASS' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
  {
    key: 'resource_buildings',
    label: 'Resource Buildings',
    endpoint: 'tables/V_WIP_RESOURCE_BUILDINGS' + sufix + query,
    order: -1,
    hasSiteFilter: true,
  },
];
