import {
  ScenarioSettingsParameters,
  ScenarioSettings as TScenarioSettings,
} from '@/store/types';
import { Parameters } from '../Parameters';
type TSetParameters = {
  form: TScenarioSettings;
  setForm: React.Dispatch<React.SetStateAction<TScenarioSettings>>;
};

const SetParameters = ({ form, setForm }: TSetParameters) => {
  return (
    <div style={{ marginBottom: 80, paddingTop: 5 }}>
      {form.parameters.map(
        (item: ScenarioSettingsParameters, index: number) => (
          <div key={index}>
            {/*  {index} - CAT: {item.DropDown_JobsPrioritisation} */}
            <Parameters
              parameters={item}
              setForm={setForm}
              parameterIndex={index}
              totalSetParameters={form.parameters.length}
            />
          </div>
        ),
      )}
    </div>
  );
};

export default SetParameters;
