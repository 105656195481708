import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { useContext } from 'react';
import { FormProductionOrders } from './FormProductionOrders';
import { TSimulationMenuOptions } from '@/store/types';
import { FormProducts } from './FormProducts';
import { FormOperations } from './FormOperations';
import { FormOperationsSteps } from './FormOperationsSteps';
import { FormResources } from './FormResources';
import { FormShifts } from './FormShifts';
import { FormShutdowns } from './FormShutdowns';
import { FormOperationsResources } from './FormOperationsResources';

export type TForm = {
  formId: string;
};

const TableView = () => {
  const { simulationParams } = useContext(SimulationCtx);

  const ShowForm = (selectedOption: TSimulationMenuOptions) => {
    switch (selectedOption) {
      case 'production_orders':
        return <FormProductionOrders />;
      case 'products':
        return <FormProducts />;
      case 'operations':
        return <FormOperations />;
      case 'operation_steps':
        return <FormOperationsSteps />;
      case 'operations_resources':
        return <FormOperationsResources />;
      case 'resources':
        return <FormResources />;
      case 'shifts':
        return <FormShifts />;
      case 'shutdowns':
        return <FormShutdowns />;

      default:
        return null;
    }
  };

  return (
    <div
      style={{
        padding: 20,
      }}
    >
      {/*  {simulationParams.formArea} */}
      {simulationParams.formArea && ShowForm(simulationParams.formArea)}
    </div>
  );
};

export default TableView;
