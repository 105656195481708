import { apiConfig } from '@/config/tokenConfig';
import { useMsal } from '@azure/msal-react';
import { JwtPayload, jwtDecode } from 'jwt-decode';

const useAccessToken = () => {
  const { instance, accounts } = useMsal();

  const tokenRequest = {
    account: accounts[0],
    scopes: apiConfig.resourceScopes,
  };

  interface IToken extends JwtPayload {
    groups: string[];
  }

  const getSilentToken = async () => {
    /*     try { */
    const tokenResponse = await instance.acquireTokenSilent(tokenRequest);
    const tokenDecoded: IToken = jwtDecode(tokenResponse.accessToken);
    localStorage.setItem('groups', JSON.stringify(tokenDecoded.groups));

    return tokenResponse.accessToken;
    /*  } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
        return;
      }
      return null;
    } */
  };

  return { getSilentToken };
};

export default useAccessToken;
