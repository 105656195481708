import {
  Select,
  SelectOption,
  Stack,
} from '@data-products-and-ai/react-components';
import { useEffect, useState } from 'react';

type TShiftsTimePicker = {
  id: string;
  seconds: number;
  onChange: (value: number) => void;
};

function secondsToHoursMinutes(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  return { hours, minutes };
}

const generateOptions = (min: number, max: number) => {
  const options = [];
  for (let i = min; i <= max; i++) {
    const time = i.toString().padStart(2, '0'); // Ensure leading zero for single-digit hours
    options.push(<SelectOption key={time} label={time} value={i.toString()} />);
  }
  return options;
};

const FormShiftsTimePicker = ({
  id,
  seconds = 0,
  onChange,
}: TShiftsTimePicker) => {
  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const { hours, minutes } = secondsToHoursMinutes(seconds);
    setHour(hours);
    setMinutes(minutes);
  }, [seconds]);

  const handleInputChange = (inputType: string, value: number) => {
    if (!value) value = 0;

    if (inputType === 'hours') {
      setHour(value);
    } else if (inputType === 'minutes') {
      setMinutes(value);
    }
    return;
  };

  useEffect(() => {
    const combinedValue = hour * 3600 + minutes * 60;

    if (onChange) {
      onChange(combinedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, hour]);

  return (
    <Stack direction="row" gap={10}>
      <Select
        onChange={(event) =>
          handleInputChange('hours', parseInt(event.target.value))
        }
        size="small"
        defaultValue={hour.toString()}
        id={`${id}_HOUR`}
      >
        {generateOptions(0, 23)}
      </Select>
      <div>:</div>
      <Select
        onChange={(event) =>
          handleInputChange('minutes', parseInt(event.target.value))
        }
        size="small"
        defaultValue={minutes.toString()}
        id={`${id}_MINUTES`}
      >
        {generateOptions(0, 59)}
      </Select>
    </Stack>
  );
};

export default FormShiftsTimePicker;
