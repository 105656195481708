import { css, styled } from 'styled-components';

export const Scenario = styled.div<{ $is_selected: boolean }>`
  position: relative;
  font-size: 12;
  padding: 8px;
  padding-left: 20px;
  padding-right: 15px;
  cursor: pointer;
  border-left: solid 4px transparent;
  ${(props) =>
    !props.$is_selected &&
    css`
      &:hover {
        background-color: #ededed;
      }
    `}

  ${(props) =>
    props.$is_selected &&
    css`
      background-color: #e6e6e6;
      border-left: solid 4px #8a00e5 !important;
    `}
`;

export const Container = styled.div`
  text-align: center;
`;
