import { hashNumber } from '@/utils/helpers';
import { ResponseObjType } from '../types';

export function transformData(UUID: string, jsonData: ResponseObjType) {
  const { data, rowType } = jsonData;

  return data.map((row) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rowObject: any = {};

    for (let i = 0; i < row.length; i++) {
      const key: string = rowType[i].name;
      let row_value: string | number = row[i];
      let rowtype_value = '';

      if (key.endsWith('_ID')) {
        rowtype_value = 'id';
      } else if (
        rowType[i].type === 'time' ||
        rowType[i].type === 'timestamp_ntz'
      ) {
        row_value = parseFloat(row_value);
        rowtype_value = rowType[i].type;
      } else if (rowType[i].type === 'fixed') {
        row_value = parseInt(row_value);
        rowtype_value = rowType[i].type;
      } else {
        rowtype_value = rowType[i].type;
      }

      rowObject[key] = { value: row_value, type: rowtype_value };

      if (key.endsWith('_ID')) {
        rowObject[key].original_value = rowObject[key].value;

        if (rowObject[key].value !== null)
          rowObject[key].value = hashNumber(
            UUID + rowObject[key].original_value,
          );

        /* guard to ensure it is a number 
       
       if (rowObject[key].value !== null && Number(rowObject[key].value))
          rowObject[key].value = hashNumber(
            UUID + rowObject[key].original_value,
          ); */
      }
    }

    return rowObject;
  });
}
