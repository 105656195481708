/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  AdditionalEquipment,
  Enterprise,
  Operation,
  OperationResource,
  OperationTypes,
  Pause,
  PauseShift,
  ProcessTypes,
  Product,
  ProductClass,
  ProductType,
  ProductionOrder,
  ResouceBuilding,
  Resource,
  ResourceClass,
  ResourceGrade,
  ResourceName,
  ResourceSubClass,
  ResourceType,
  SetAdditionalEquipment,
  SetPause,
  SetWorkUnit,
  Shift,
  ShiftCalendar,
  Shutdown,
  Site,
  Wip,
  WorkPlan,
  WorkUnit,
} from '@/store/types';

type Item = {
  [key: string]: any;
  parent?: string;
  is_hidden: boolean;
  is_wip: boolean;
  is_disabled: boolean;
  is_deleted: boolean;
  is_open: boolean;
  is_selected: boolean;
  is_selectable: boolean;
  is_match: boolean;
  children: string[];
};

type ById = {
  [key: string]: Item;
};

type TResult<T extends ById> = {
  byId: T;
  allIds: string[];
};

function createTree<T>(
  data: any[],
  idKey: keyof T,
  parentKey: keyof T | null,
  otherKeys: Array<keyof T> = [],
): T {
  const result: TResult<ById> = {
    byId: {},
    allIds: [],
  };

  for (let i = 0; i < data.length; i++) {
    const item: Item = {
      [idKey]: {
        value: data[i][idKey].value,
        originalValue: data[i][idKey].value,
      },
      is_wip: true,
      is_hidden: false,
      is_disabled: false,
      is_deleted: false,
      is_open: false,
      is_selected: false,
      is_selectable: false,
      is_match: false,
      children: [],
    };

    if (parentKey !== null) {
      item[parentKey as string] = {
        value: data[i][parentKey as string],
        originalValue: data[i][parentKey as string],
      };
    }

    for (const key of otherKeys) {
      try {
        item[key as string] = {
          value: data[i][key as string].value,

          originalValue: data[i][key as string].value,
        };
      } catch (e) {
        console.error('Error creating tree:', key, e);
      }
    }

    if (parentKey !== null) item.parent = data[i][parentKey].value;

    result.byId[data[i][idKey].value] = item;
    result.allIds.push(data[i][idKey].value);
  }

  //second loop filling the children if parentKey exists
  if (parentKey !== null) {
    for (let i = 0; i < data.length; i++) {
      if (
        data[i][parentKey].value !== null &&
        result.byId[data[i][parentKey].value]
      ) {
        result.byId[data[i][parentKey].value].children.push(
          data[i][idKey].value,
        );
      }
    }
  }
  return result as T;
}

function createTreeChildren<T>(
  data: TResult<ById>,
  parentData: TResult<ById>,
  idKey: keyof T,
): TResult<ById> {
  console.log('PARENT DATA', parentData);

  for (let i = 0; i < data.allIds.length; i++) {
    const temp = data.byId[data.allIds[i]][idKey as string].value;
    parentData.byId[temp].children.push(data.allIds[i]);

    /*  if (temp) {
    } else {
      console.log(i, data.allIds[i]);
    } */
  }

  return parentData;
}

type WipKeys = keyof Wip;

type TreeGenerator = {
  [K in WipKeys]: (data: any) => any;
};

type TreeChildrenGenerator = {
  [K in WipKeys]: (data: any, parentData: any) => any;
};

export const generateTree: TreeGenerator = {
  production_orders: (data: any) =>
    createTree<ProductionOrder>(
      data,
      'PRODUCTION_ORDER_ID',
      'PARENT_PRODUCTION_ORDER_ID',
      [
        'PARENT_PRODUCTION_ORDER_ID',
        'PRODUCTION_ORDER',
        'PO_END_DATE_TIMESTAMP',
        'PO_START_DATE_TIMESTAMP',
        'QUANTITY_ASSEMBLED_PARTS',
        'SITE_ID',
        'WORK_PLAN_ID',
        'PRODUCT_ID',
        'END_PRODUCTION_ORDER_ID',
        'END_PRODUCT_ID',
        'PO_LAST_STEP_WIP',
        'PO_QUANTITY',
      ],
    ),

  products: (data: any) =>
    createTree<Product>(data, 'PRODUCT_ID', 'PARENT_PRODUCT_ID', [
      'PARENT_PRODUCT_ID',
      'PRODUCT',
      'PRODUCT_CLASS',
      'PRODUCT_TYPE',
      'END_PRODUCT_ID',
      'SITE_ID',
    ]),
  additional_equipments: (data: any) =>
    createTree<AdditionalEquipment>(data, 'ADDITIONAL_EQUIPMENT_ID', null, [
      'ADDITIONAL_EQUIPMENT',
      'ADDITIONAL_EQUIPMENT_AVAILABILITY_ILLNESS',
      'ADDITIONAL_EQUIPMENT_AVAILABILITY_TECH',
      'ADDITIONAL_EQUIPMENT_AVAILABILITY_VACATION',
      'ADDITIONAL_EQUIPMENT_CLASS',
      'ADDITIONAL_EQUIPMENT_GRADE',
      'ADDITIONAL_EQUIPMENT_MAX_CAPACITY',
      'ADDITIONAL_EQUIPMENT_MTTR_ILLNESS',
      'ADDITIONAL_EQUIPMENT_MTTR_TECH',
      'ADDITIONAL_EQUIPMENT_MTTR_VACATION',
      'ADDITIONAL_EQUIPMENT_SUB_CLASS',
      'SHIFT_CALENDAR_ID',
      'SITE_ID',
    ]),

  operations: (data: any) =>
    createTree<Operation>(data, 'OPERATION_ID', null, [
      'BATCH_BLK_SUFFIX',
      'MAX_QUANTITY_IN_BATCH',
      'OPERATION',
      'OPERATION_BUSINESS',
      'OPERATION_TYPE',
      'PERSONNEL_TIME',
      'PROCESS',
      'PROCESS_TIME',
      'SETUP_TIME',
      'SET_OF_ADDITIONAL_EQUIPMENT_ID',
      'SET_OF_OPERATION_QUALIFICATION_ID',
      'SET_OF_WORK_UNIT_ID',
      'OPERATION_TYPE_ID',
      'PROCESS_TYPE_ID',
      'SITE_ID',
      'STEP',
      'WORK_PLAN_ID',
    ]),

  pauses: (data: any) =>
    createTree<Pause>(data, 'PAUSE_ID', null, [
      'START_TIME',
      'END_TIME',
      'SITE_ID',
    ]),

  set_additional_equipments: (data: any) =>
    createTree<SetAdditionalEquipment>(data, 'ADDITIONAL_EQUIPMENT_ID', null, [
      'ADDITIONAL_EQUIPMENT_PRIORITY',
      'SITE_ID',
    ]),

  set_pauses: (data: any) =>
    createTree<SetPause>(data, 'SET_OF_PAUSE_ID', null, [
      'SHIFT_ID',
      'SHIFT_CALENDAR_ID',
      'PAUSE_ID',
      'SITE_ID',
      'END_TIME',
      'START_TIME',
    ]),

  sites: (data: any) =>
    createTree<Site>(data, 'SITE_ID', null, [
      'SITE_ID',
      'SITE',
      'SITE_ABBREVIATION',
    ]),

  set_work_units: (data: any) =>
    createTree<SetWorkUnit>(data, 'SET_OF_WORK_UNIT_ID', null, [
      'SITE_ID',
      'WORK_UNIT_ID',
      'WORK_UNIT_PRIORITY',
    ]),

  shift_calendar: (data: any) =>
    createTree<ShiftCalendar>(data, 'SHIFT_CALENDAR_ID', null, [
      'SHIFT_CALENDAR',
      'SITE_ID',
    ]),

  shifts: (data: any) =>
    createTree<Shift>(data, 'SHIFT_ID', null, [
      'END_TIME',
      'FR',
      'MO',
      'SA',
      'SET_OF_PAUSE_ID',
      'SHIFT_CALENDAR_ID',
      'SHIFT',
      'SITE_ID',
      'START_TIME',
      'SU',
      'TH',
      'TU',
      'WE',
    ]),
  shutdowns: (data: any) =>
    createTree<Shutdown>(data, 'SHUTDOWN_ID', null, [
      'REDUCED_ON',
      'REDUCED_TIME',
      'SHUTDOWN_DATE',
      'SHUTDOWN_TITLE',
      'SHUTDOWN_TYPE',
      'SITE_ID',
      'WORKING_OR_NOT',
    ]),

  work_plans: (data: any) =>
    createTree<WorkPlan>(data, 'WORK_PLAN_ID', null, ['WORK_PLAN', 'SITE_ID']),

  work_units: (data: any) =>
    createTree<WorkUnit>(data, 'WORK_UNIT_ID', null, [
      'SHIFT_CALENDAR_ID',
      'SITE_ID',
      'WORK_UNIT',
      'WORK_UNIT_AVAILABILITY_ILLNESS',
      'WORK_UNIT_AVAILABILITY_TECH',
      'WORK_UNIT_AVAILABILITY_VACATION',
      'WORK_UNIT_CLASS',
      'WORK_UNIT_GRADE',
      'WORK_UNIT_MAX_CAPACITY',
      'WORK_UNIT_MTTR_ILLNESS',
      'WORK_UNIT_MTTR_TECH',
      'WORK_UNIT_MTTR_VACATION',
      'WORK_UNIT_SUB_CLASS',
    ]),

  workplan_processes: (data: any) => {
    console.log(data);
  },
  workunit_classes: (data: any) => {
    console.log(data);
  },
  additionalequipment_classes: (data: any) => {
    console.log(data);
  },

  process_types: (data: any) =>
    createTree<ProcessTypes>(data, 'PROCESS_TYPE_ID', null, [
      'PROCESS',
      'SITE_ID',
    ]),

  operation_types: (data: any) =>
    createTree<OperationTypes>(data, 'OPERATION_TYPE_ID', null, [
      'OPERATION',
      'SITE_ID',
    ]),

  resources: (data: any) =>
    createTree<Resource>(data, 'RESOURCE_ID', null, [
      'BUILDING_ID',
      'RESOURCE_AVAILABILITY_ILLNESS',
      'RESOURCE_AVAILABILITY_TECH',
      'RESOURCE_AVAILABILITY_VACATION',
      'RESOURCE_CLASS_ID',
      'RESOURCE_GRADE_ID',
      'RESOURCE_MAX_CAPACITY',
      'RESOURCE_MTTR_ILLNESS',
      'RESOURCE_MTTR_TECH',
      'RESOURCE_MTTR_VACATION',
      'RESOURCE_NAME',
      'RESOURCE_SHIFT_CALENDAR_ID',
      'RESOURCE_SUB_CLASS_ID',
      'RESOURCE_TYPE_ID',
      'SITE_ID',
    ]),

  resource_names: (data: any) =>
    createTree<ResourceName>(data, 'RESOURCE_NAME_ID', null, [
      'RESOURCE_NAME',
      'SITE_ID',
    ]),

  resource_types: (data: any) =>
    createTree<ResourceType>(data, 'RESOURCE_TYPE_ID', null, [
      'RESOURCE_TYPE',
      'SITE_ID',
    ]),

  resource_classes: (data: any) =>
    createTree<ResourceClass>(data, 'RESOURCE_CLASS_ID', null, [
      'RESOURCE_CLASS',
      'RESOURCE_TYPE_ID',
    ]),

  resource_sub_classes: (data: any) =>
    createTree<ResourceSubClass>(data, 'RESOURCE_SUB_CLASS_ID', null, [
      'RESOURCE_SUB_CLASS',
      'RESOURCE_CLASS_ID',
    ]),

  resource_grades: (data: any) =>
    createTree<ResourceGrade>(data, 'RESOURCE_GRADE_ID', null, [
      'RESOURCE_GRADE',
      'SITE_ID',
    ]),

  operations_resources: (data: any) =>
    createTree<OperationResource>(data, 'OPERATIONS_RESOURCES_ID', null, [
      'OPERATION_ID',
      'RESOURCE_ID',
      'RESOURCE_PRIORITY',
      /*  'SITE_ID', */
    ]),

  pause_shift: (data: any) =>
    createTree<PauseShift>(data, 'PAUSE_SHIFT_ID', null, [
      'PAUSE_ID',
      'SHIFT_ID',
      'SITE_ID',
    ]),
  enterprises: (data: any) =>
    createTree<Enterprise>(data, 'ENTERPRISE_ID', null, ['ENTERPRISE']),
  product_classes: (data: any) =>
    createTree<ProductClass>(data, 'PRODUCT_CLASS_ID', null, [
      'PRODUCT_CLASS',
      'SITE_ID',
    ]),
  product_types: (data: any) =>
    createTree<ProductType>(data, 'PRODUCT_TYPE_ID', null, [
      'PRODUCT_TYPE',
      'SITE_ID',
    ]),
  resource_buildings: (data: any) =>
    createTree<ResouceBuilding>(data, 'BUILDING_ID', null, [
      'BUILDING',
      'SITE_ID',
    ]),
};

export const addTreeChildren: Pick<
  TreeChildrenGenerator,
  'shifts' | 'operations'
> = {
  shifts: (data: any, parentData: any) =>
    createTreeChildren<Shift>(data, parentData, 'SHIFT_CALENDAR_ID'),
  operations: (data: any, parentData: any) =>
    createTreeChildren<Operation>(data, parentData, 'WORK_PLAN_ID'),
};

/* export function createTree(data: any[], parentId = null): productionOrderType {
  const result: productionOrderType = {
    byId: {},
    allIds: [],
  };

  for (let i = 0; i < data.length; i++) {
    result.byId[data[i].PRODUCTION_ORDER_ID.value] = {
      PRODUCTION_ORDER_ID: data[i].PRODUCTION_ORDER_ID.value,
      PRODUCTION_ORDER: data[i].PRODUCTION_ORDER.value,
      PO_END_DATE_TIMESTAMP: data[i].PO_END_DATE_TIMESTAMP.value,
      PO_START_DATE_TIMESTAMP: data[i].PO_START_DATE_TIMESTAMP.value,
      QUANTITY_ASSEMBLED_PARTS: data[i].QUANTITY_ASSEMBLED_PARTS.value,
      SITE_ID: data[i].SITE_ID.value,
      WORK_PLAN_ID: data[i].WORK_PLAN_ID.value,
      PRODUCT_ID: data[i].PRODUCT_ID.value,
      PARENT_PRODUCTION_ORDER_ID: data[i].PARENT_PRODUCTION_ORDER_ID.value,
      parent: data[i].PARENT_PRODUCTION_ORDER_ID.value,
      children: [],
    };
    result.allIds.push(data[i].PRODUCTION_ORDER_ID.value);
  }

  for (let i = 0; i < data.length; i++) {
    if (
      data[i].PARENT_PRODUCTION_ORDER_ID.value !== null &&
      result.byId[data[i].PARENT_PRODUCTION_ORDER_ID.value]
    ) {
      result.byId[data[i].PARENT_PRODUCTION_ORDER_ID.value].children.push(
        data[i].PRODUCTION_ORDER_ID.value,
      );
    }
  }

  return result;
}
 */
