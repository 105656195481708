export const convertStringToDate = (dateString: string) => {
  const date = new Date(dateString);

  const year = date.getFullYear().toString().substring(2, 4);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const result = year + month + day;
  return result;
};
