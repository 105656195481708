/* eslint-disable @typescript-eslint/no-explicit-any */
// utils/hashNumber.ts
import * as CryptoJS from 'crypto-js';

export function convertToLocalTime(dateString: string) {
  const date = new Date(dateString + 'Z');
  return date.toLocaleString();
}

export function getTimeInterval(dateString1: string, dateString2: string) {
  if (!dateString1 || !dateString2) return null;

  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  const milliseconds = Math.abs(date2.valueOf() - date1.valueOf());
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes} minutes and ${seconds
    .toString()
    .padStart(2, '0')} seconds`;
}

export const generateUID = () => {
  const UT = new Date().valueOf();
  const Salt = Math.floor(Math.random() * 100000);
  const UID = `${UT}${Salt}`;

  return UID;
};

export function hashNumber(
  number: number | string,
  minLength: number = 16,
  maxLength: number = 38,
): string {
  const hash = CryptoJS.SHA256(number.toString()).toString(CryptoJS.enc.Hex);

  return hash;
  //deprecated - returned a number instead of string
  // Determine the length of the truncated hash based on minLength and maxLength
  const hashLength = Math.min(Math.max(minLength, 16), maxLength);
  const numCharsToTruncate = Math.ceil((hashLength * 4) / 5); // SHA-256 produces 4 bits of output per 5 bits of input

  // Truncate the hash to get the desired length
  const truncatedHash = hash.slice(0, numCharsToTruncate);
  const hashedBigInt = BigInt(`0x${truncatedHash}`);

  return hashedBigInt.toString();
  return hashedBigInt.toString().padStart(hashLength, '0');
}

export const getFromLocalStorage = (storageKey: string) => {
  return localStorage.getItem(storageKey);
};

export const toUnixTime = (
  year: number,
  month: number,
  day: number,
  hour: number = 0,
  minutes: number = 0,
) => {
  const date = new Date(Date.UTC(year, month - 1, day, hour, minutes));
  return Math.floor(date.getTime() / 1000);
};

type AllowedDateTypes =
  | 'yyyy/mm/dd'
  | 'dd/mm/yyyy'
  | 'mm/yyyy'
  | 'yyyy.mm.dd'
  | 'dd.mm.yyyy'
  | 'mm.yyyy';

type TTime = {
  time: string;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  totalSeconds: number;
};
export function formatHoursToTime(hours: number): TTime {
  let totalSeconds = hours * 3600;

  if (hours <= 0) {
    totalSeconds = 0;
  } else {
    totalSeconds = hours * 3600;
  }

  const daysValue = Math.floor(totalSeconds / 3600 / 24);
  const hoursValue = Math.floor(totalSeconds / 3600);
  const minutesValue = Math.floor((totalSeconds % 3600) / 60);
  const secondsValue = Math.floor(totalSeconds % 60);

  return {
    time:
      hours <= 0 ? 'N/A' : `${hoursValue}h ${minutesValue}m ${secondsValue}s`,
    days: daysValue,
    hours: hoursValue,
    minutes: minutesValue,
    seconds: secondsValue,
    totalSeconds: Math.round(totalSeconds),
  };
}
export function formatSecondsToHours(seconds: number): number {
  return seconds / 3600;
}
export function unixTimestampToDate(
  unixTimestamp: number | string,
  format?: AllowedDateTypes,
) {
  //Check if a number or a string
  const timestampInMilliseconds =
    typeof unixTimestamp === 'number'
      ? unixTimestamp * 1000
      : parseInt(unixTimestamp) * 1000;

  // Multiply by 1000 to convert the Unix timestamp to milliseconds
  const date = new Date(timestampInMilliseconds);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
  const year = date.getUTCFullYear();

  //return Type is string - default
  if (format === 'yyyy/mm/dd') return `${year}/${month}/${day}`;
  if (format === 'mm/yyyy') return `${month}/${year}`;
  if (format === 'yyyy.mm.dd') return `${year}.${month}.${day}`;
  if (format === 'mm.yyyy') return `${month}.${year}`;
  if (format === 'dd.mm.yyyy') return `${day}.${month}.${year}`;

  return `${day}/${month}/${year}`;
}

export function unixTimestampToObject(unixTimestamp: number | string) {
  //Check if a number or a string
  const timestampInMilliseconds =
    typeof unixTimestamp === 'number'
      ? unixTimestamp * 1000
      : parseInt(unixTimestamp) * 1000;

  // Multiply by 1000 to convert the Unix timestamp to milliseconds
  const date = new Date(timestampInMilliseconds);

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  return { year: year, month: month, day: day };
}

const sortTree = (treeData: any[]) => {
  // Sort the current level alphabetically by title

  treeData.sort((a: any, b: any) => {
    const titleA = a.title.trim().toLowerCase();
    const titleB = b.title.trim().toLowerCase();

    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });

  // Recursively sort the children
  treeData.forEach((item: any) => {
    if (Array.isArray(item.children) && item.children.length > 0) {
      sortTree(item.children);
    }
  });
  return treeData;
};

export const generateTree: any = {
  production_orders: (data: any) => {
    if (!data) return;

    const PRODUCTION_ORDERS_LIST: any[] = [];
    const SITE_PREFIX = 'SITE_';
    //get processes
    data.forEach((item: any) => {
      const existingItem = PRODUCTION_ORDERS_LIST.find(
        (treeItem) => treeItem.key === SITE_PREFIX + item.SITE_ID.value,
      );

      if (!existingItem) {
        const mappedItem = {
          key: SITE_PREFIX + item.SITE_ID.value,
          title: item.SITE.value,
          is_hidden: true,
          is_wip: true,
          is_deleted: false,
          is_disabled: false,
          is_clicked: false,
          is_selected: false,
          is_selectable: false,
          is_match: false,
          children: [],
        };
        PRODUCTION_ORDERS_LIST.push(mappedItem);
      }
    });

    const treeData = mapTree(
      data,
      'PRODUCTION_ORDER_ID',
      'PRODUCTION_ORDER',
      'PARENT_PRODUCTION_ORDER_ID',
      'END_PRODUCTION_ORDER_ID',
    );

    PRODUCTION_ORDERS_LIST.forEach((item: any) => {
      treeData.forEach((process_item: any) => {
        if (process_item.SITE_ID_KEY === item.key) {
          if (item.is_disabled === false) process_item.is_disabled = false;
          item.children.push(process_item);
        }
      });
    });

    return sortTree(PRODUCTION_ORDERS_LIST);

    // return sortTree(treeData);
  },
  products: (data: any) => {
    if (!data) return;

    const PRODUCTS_LIST: any[] = [];
    const SITE_PREFIX = 'SITE_';
    //get processes
    data.forEach((item: any) => {
      const existingItem = PRODUCTS_LIST.find(
        (treeItem) => treeItem.key === SITE_PREFIX + item.SITE_ID.value,
      );

      if (!existingItem) {
        const mappedItem = {
          key: SITE_PREFIX + item.SITE_ID.value,
          title: item.SITE.value,
          is_hidden: true,
          is_wip: true,
          is_deleted: false,
          is_disabled: false,
          is_clicked: false,
          is_selected: false,
          is_selectable: false,
          is_match: false,
          children: [],
        };
        PRODUCTS_LIST.push(mappedItem);
      }
    });

    const treeData = mapTree(
      data,
      'PRODUCT_ID',
      'PRODUCT',
      'PARENT_PRODUCT_ID',
      'END_PRODUCT_ID',
    );

    PRODUCTS_LIST.forEach((item: any) => {
      treeData.forEach((process_item: any) => {
        if (process_item.SITE_ID_KEY === item.key) {
          item.children.push(process_item);
        }
      });
    });

    return sortTree(PRODUCTS_LIST);
  },
  operations: (data: any) => {
    if (!data) return;

    const PROCESS_LIST: any[] = [];
    const SITE_PREFIX = 'SITE_';
    data.forEach((item: any) => {
      const existingItem = PROCESS_LIST.find(
        (treeItem) => treeItem.key === SITE_PREFIX + item.SITE_ID.value,
      );

      if (!existingItem) {
        const mappedItem = {
          key: SITE_PREFIX + item.SITE_ID.value,
          title: item.SITE.value,
          is_hidden: true,
          is_wip: true,
          is_deleted: false,
          is_disabled: false,
          is_clicked: false,
          is_selected: false,
          is_selectable: false,
          is_match: false,
          children: [],
        };
        PROCESS_LIST.push(mappedItem);
      }
    });

    //get processes
    data.forEach((item: any) => {
      const mappedItem = {
        key: 'PR_' + item.PROCESS.value,
        title: item.PROCESS.value,
        is_hidden: false,
        is_wip: true,
        is_deleted: false,
        is_disabled: false,
        is_clicked: false,
        is_selected: false,
        is_selectable: false,
        is_match: false,
        children: [],
      };

      PROCESS_LIST.forEach((process_item: any) => {
        if (process_item.key === SITE_PREFIX + item.SITE_ID.value) {
          const existingItem = process_item.children.find(
            (treeItem: { key: { value: any } }) =>
              (treeItem.key as unknown as string) ===
              'PR_' + item.PROCESS.value,
          );

          if (!existingItem) process_item.children.push(mappedItem);
        }
      });
    });

    //get workplans
    data.forEach((item: any) => {
      const mappedItem = {
        key: 'WK_' + item.WORK_PLAN_ID.value,
        title: item.WORK_PLAN.value,
        is_hidden: false,
        is_wip: true,
        is_deleted: false,
        is_disabled: false,
        is_clicked: false,
        is_selected: false,
        is_selectable: false,
        is_match: false,
        children: [],
      };

      PROCESS_LIST.forEach((workplan_item: any) => {
        workplan_item.children.forEach((process_item: any) => {
          if (process_item.key === 'PR_' + item.PROCESS.value) {
            const existingItem = process_item.children.find(
              (treeItem: { key: { value: any } }) =>
                (treeItem.key as unknown as string) ===
                'WK_' + item.WORK_PLAN_ID.value,
            );

            if (!existingItem) process_item.children.push(mappedItem);
          }
        });
      });
    });

    //return sortTree(PROCESS_LIST);
    //get operations
    data.forEach((item: any) => {
      const mappedItem = {
        ...item,
        key: item.OPERATION_ID.value,
        title: item.STEP.value + ' - ' + item.OPERATION.value,
        is_hidden: false,

        is_clicked: false,
        is_selected: false,
        is_selectable: true,
        is_match: false,
        children: [],
      };
      if (!('is_disabled' in mappedItem)) mappedItem.is_disabled = true;
      PROCESS_LIST.forEach((workplan_item: any) => {
        workplan_item.children.forEach((processes: any) => {
          processes.children.forEach((p_item: any) => {
            if (p_item.key === 'WK_' + item.WORK_PLAN_ID.value) {
              const existingItem = p_item.children.find(
                (treeItem: { key: { value: any } }) =>
                  treeItem.key === item.OPERATION_ID.value,
              );

              if (!existingItem) p_item.children.push(mappedItem);
            }
          });
        });
      });
    });
    return sortTree(PROCESS_LIST);
  },

  work_units: (data: any) => {
    if (!data) return;

    const WORKUNIT_LIST: any[] = [];
    const SITE_PREFIX = 'SITE_';
    //get processes
    data.forEach((item: any) => {
      const existingItem = WORKUNIT_LIST.find(
        (treeItem) => treeItem.key === SITE_PREFIX + item.SITE_ID.value,
      );

      if (!existingItem) {
        const mappedItem = {
          key: SITE_PREFIX + item.SITE_ID.value,
          title: item.SITE.value,
          is_hidden: true,
          is_wip: true,
          is_deleted: false,
          is_disabled: false,
          is_clicked: false,
          is_selected: false,
          is_selectable: false,
          is_match: false,
          children: [],
        };
        WORKUNIT_LIST.push(mappedItem);
      }
    });

    //get Work Unit Classes
    data.forEach((item: any) => {
      const mappedItem = {
        key: 'C_' + item.WORK_UNIT_CLASS.value,
        title: item.WORK_UNIT_CLASS.value,
        is_hidden: false,
        is_wip: true,
        is_deleted: false,
        is_disabled: false,
        is_clicked: false,
        is_selected: false,
        is_selectable: false,
        is_match: false,
        children: [],
      };

      WORKUNIT_LIST.forEach((site_item: any) => {
        if (site_item.key === SITE_PREFIX + item.SITE_ID.value) {
          const existingItem = site_item.children.find(
            (treeItem: { key: { value: any } }) =>
              (treeItem.key as unknown as string) ===
              `C_${item.WORK_UNIT_CLASS.value}`,
          );

          if (!existingItem) site_item.children.push(mappedItem);
        }
      });
    });

    //get Work Unit SubClasses
    data.forEach((item: any) => {
      const mappedItem = {
        key:
          'SC_' +
          item.WORK_UNIT_CLASS.value +
          '_' +
          item.WORK_UNIT_SUB_CLASS.value,
        title: item.WORK_UNIT_SUB_CLASS.value,
        WORK_UNIT_CLASS: item.WORK_UNIT_CLASS.value,
        SITE_ID: item.SITE_ID.value,
        is_hidden: false,
        is_wip: true,
        is_deleted: false,
        is_disabled: false,
        is_clicked: false,
        is_selected: false,
        is_selectable: false,
        is_match: false,
        children: [],
      };

      WORKUNIT_LIST.forEach((resource_item: any) => {
        resource_item.children.forEach((workplan_item: any) => {
          const existingItem = workplan_item.children.find(
            (treeItem: { key: { value: any } }) =>
              (treeItem.key as unknown as string) ===
              `SC_${item.WORK_UNIT_CLASS.value}_${item.WORK_UNIT_SUB_CLASS.value}`,
          );

          if (workplan_item.key === `C_${item.WORK_UNIT_CLASS.value}`) {
            if (!existingItem) workplan_item.children.push(mappedItem);
          }
        });
      });
    });

    //get work_units
    data.forEach((item: any) => {
      const mappedItem = {
        ...item,
        key: item.WORK_UNIT_ID.value,
        title: item.WORK_UNIT.value,
        is_hidden: false,

        is_clicked: false,
        is_selected: false,
        is_selectable: true,
        is_match: false,
        children: [],
      };
      if (!('is_disabled' in mappedItem)) mappedItem.is_disabled = true;
      /* WORKUNIT_LIST.forEach((resource_item: any) => {
        resource_item.children.forEach((workplan_item: any) => {
          if (workplan_item.key === item.WORK_UNIT_SUB_CLASS.value) {
            workplan_item.children.push(mappedItem);
          }
        });
      }); */

      WORKUNIT_LIST.forEach((resource_item: any) => {
        resource_item.children.forEach((workplan_item: any) => {
          workplan_item.children.forEach((workplan_item2: any) => {
            if (
              workplan_item2.key ===
              `SC_${item.WORK_UNIT_CLASS.value}_${item.WORK_UNIT_SUB_CLASS.value}`
            ) {
              workplan_item2.children.push(mappedItem);
            }
          });
        });
      });
    });

    return sortTree(WORKUNIT_LIST);
  },
  additional_equipments: (data: any) => {
    if (!data) return;

    const ADDITIONAL_LIST: any[] = [];
    const SITE_PREFIX = 'SITE_';
    //get processes
    data.forEach((item: any) => {
      const existingItem = ADDITIONAL_LIST.find(
        (treeItem) => treeItem.key === SITE_PREFIX + item.SITE_ID.value,
      );

      if (!existingItem) {
        const mappedItem = {
          key: SITE_PREFIX + item.SITE_ID.value,
          title: item.SITE.value,
          is_hidden: true,
          is_wip: true,
          is_deleted: false,
          is_disabled: false,
          is_clicked: false,
          is_selected: false,
          is_selectable: false,
          is_match: false,
          children: [],
        };
        ADDITIONAL_LIST.push(mappedItem);
      }
    });
    //get Work Unit Classes
    data.forEach((item: any) => {
      const mappedItem = {
        ...item,
        key: item.ADDITIONAL_EQUIPMENT_ID.value,
        title: item.ADDITIONAL_EQUIPMENT.value,
        is_hidden: false,

        is_clicked: false,
        is_selected: false,
        is_selectable: true,
        is_match: false,
        children: [],
      };
      if (!('is_disabled' in mappedItem)) mappedItem.is_disabled = true;
      ADDITIONAL_LIST.forEach((site_item: any) => {
        if (site_item.key === SITE_PREFIX + item.SITE_ID.value) {
          const existingItem = site_item.children.find(
            (treeItem: { key: { value: any } }) =>
              treeItem.key === item.ADDITIONAL_EQUIPMENT_ID.value,
          );

          if (!existingItem) site_item.children.push(mappedItem);
        }
      });
    });

    return sortTree(ADDITIONAL_LIST);
  },

  shifts: (data: any) => {
    if (!data) return;

    const SHIFTS_LIST: any[] = [];
    const SITE_PREFIX = 'SITE_';
    //get processes
    data.forEach((item: any) => {
      const existingItem = SHIFTS_LIST.find(
        (treeItem) => treeItem.key === SITE_PREFIX + item.SITE_ID.value,
      );

      if (!existingItem) {
        const mappedItem = {
          key: SITE_PREFIX + item.SITE_ID.value,
          title: item.SITE.value,
          is_hidden: true,
          is_wip: true,
          is_deleted: false,
          is_disabled: false,
          is_clicked: false,
          is_selected: false,
          is_selectable: false,
          is_match: false,
          children: [],
        };
        SHIFTS_LIST.push(mappedItem);
      }
    });

    data.forEach((item: any) => {
      const mappedItem = {
        key: 'SC_' + item.SHIFT_CALENDAR_ID.value,
        title: item.SHIFT_CALENDAR.value,
        is_hidden: false,
        is_wip: true,
        is_deleted: false,
        is_disabled: false,
        is_clicked: false,
        is_selected: false,
        is_selectable: false,
        is_match: false,
        children: [],
      };

      SHIFTS_LIST.forEach((site_item: any) => {
        if (site_item.key === SITE_PREFIX + item.SITE_ID.value) {
          const existingItem = site_item.children.find(
            (treeItem: { key: { value: any } }) =>
              (treeItem.key as unknown as string) ===
              `SC_${item.SHIFT_CALENDAR_ID.value}`,
          );

          if (!existingItem) site_item.children.push(mappedItem);
        }
      });
    });
    //get Work Unit SubClasses
    data.forEach((item: any) => {
      const mappedItem = {
        ...item,
        key: item.SHIFT_ID.value,
        title: item.SHIFT.value,

        is_hidden: false,
        is_clicked: false,
        is_selected: false,
        is_selectable: true,
        is_match: false,
        children: [],
      };
      if (!('is_disabled' in mappedItem)) mappedItem.is_disabled = true;
      SHIFTS_LIST.forEach((resource_item: any) => {
        resource_item.children.forEach((workplan_item: any) => {
          const existingItem = workplan_item.children.find(
            (treeItem: { key: string }) => treeItem.key === item.SHIFT_ID.value,
          );

          if (workplan_item.key === 'SC_' + item.SHIFT_CALENDAR_ID.value) {
            if (!existingItem) workplan_item.children.push(mappedItem);
          }
        });
      });
    });
    return sortTree(SHIFTS_LIST);
  },
  shutdowns: (data: any) => {
    if (!data) return;

    const SHUTDOWNS_LIST: any[] = [];

    const SITE_PREFIX = 'SITE_';

    data.forEach((item: any) => {
      const existingItem = SHUTDOWNS_LIST.find(
        (treeItem) => treeItem.key === SITE_PREFIX + item.SITE_ID.value,
      );

      if (!existingItem) {
        const mappedItem = {
          key: SITE_PREFIX + item.SITE_ID.value,
          title: item.SITE.value,
          is_hidden: true,
          is_wip: true,
          is_deleted: false,
          is_disabled: false,
          is_clicked: false,
          is_selected: false,
          is_selectable: false,
          is_match: false,
          children: [],
        };
        SHUTDOWNS_LIST.push(mappedItem);
      }
    });

    data.forEach((item: any) => {
      const date = new Date(item.SHUTDOWN_DATE.value * 1000);
      const year = date.getFullYear();

      const mappedItem = {
        key: year.toString(),
        title: year.toString(),
        is_wip: true,
        is_hidden: false,
        is_deleted: false,
        is_disabled: false,
        is_clicked: false,
        is_selected: false,
        is_selectable: false,
        is_match: false,
        children: [],
      };

      SHUTDOWNS_LIST.forEach((site_item: any) => {
        if (site_item.key === SITE_PREFIX + item.SITE_ID.value) {
          const existingItem = site_item.children.find(
            (treeItem: { key: { value: any } }) =>
              (treeItem.key as unknown as string) === year.toString(),
          );

          if (!existingItem) site_item.children.push(mappedItem);
        }
      });
    });

    data.forEach((item: any) => {
      const date = new Date(item.SHUTDOWN_DATE.value * 1000);
      const year = date.getFullYear().toString();
      const mappedItem = {
        ...item,
        key: item.SHUTDOWN_ID.value,
        title: item.SHUTDOWN_TITLE.value,
        SHUTDOWN_DATE: item.SHUTDOWN_DATE,
        is_hidden: false,
        is_clicked: false,
        is_selected: false,
        is_selectable: true,
        is_match: false,
        children: [],
      };
      if (!('is_disabled' in mappedItem)) mappedItem.is_disabled = true;
      SHUTDOWNS_LIST.forEach((resource_item: any) => {
        resource_item.children.forEach((workplan_item: any) => {
          if (workplan_item.key === year) {
            workplan_item.children.push(mappedItem);
          }
        });
      });
    });

    return sortTree(SHUTDOWNS_LIST);
  },
};

function mapTree(
  array: any,
  itemLabel: string,
  itemTitle: string,
  parentLabel: string,
  endLabel: string,
) {
  try {
    const treeMap = array
      .map((item: any) => {
        const mappedItem = {
          ...item,
          key: item[itemLabel].value,
          title: item[itemTitle].value,
          SITE_ID_KEY: 'SITE_' + item.SITE_ID.value,
          is_selectable: true,
          is_selected: false,
          is_hidden: false,
          is_clicked: false,
          is_match: false,
          children: [],
        };
        if (!('is_disabled' in mappedItem)) mappedItem.is_disabled = true;

        if (
          item[parentLabel].value === null ||
          item[parentLabel].value === '0'
        ) {
          // mappedItem.is_hidden = true;
          mappedItem.children = mapChildren(
            array,
            item[itemLabel].value,
            itemLabel,
            itemTitle,
            parentLabel,
            endLabel,
          );
        }

        return mappedItem;
      })
      .filter((item: any) => item[endLabel].value === item[itemLabel].value);

    return treeMap;
  } catch (event) {
    console.log(event);
  }
}

function mapChildren(
  data: any,
  parentId = null,
  itemLabel: string,
  itemTitle: string,
  parentLabel: string,
  endLabel: string,
) {
  const children = data.filter(
    (item: any) => item[parentLabel].value === parentId,
  );

  return children.map((child: any) => {
    const baseObj = {
      ...child,
      key: child[itemLabel].value,
      title: child[itemTitle].value,
      is_selectable: true,
      is_selected: false,
      is_hidden: false,
      is_clicked: false,
      is_match: false,
      children: mapChildren(
        data,
        child[itemLabel].value,
        itemLabel,
        itemTitle,
        parentLabel,
        endLabel,
      ),
    };

    // Add the 'is_disabled' key only if it's not already defined.
    if (child.is_disabled === undefined) {
      baseObj['is_disabled'] = true;
    }

    return baseObj;
  });
}

export function generateRangeArray(start: number, end: number): number[] {
  const rangeArray = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index,
  );
  return rangeArray;
}

/* 
const treeData = mapTree(products);

const newtree = [];

treeData.forEach((element) => {
  newtree.push(element.children[0]);
}); */
